<template>
  <div class="mt-5 intro-y box">
    <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <h2 class="mr-auto text-base font-medium">User Setting</h2>
    </div>
    <div class="p-5">
      <div class="grid grid-cols-12 gap-x-5">
        <div class="col-span-12 xl:col-span-6">
          <div>
            <label for="profile-form-1" class="form-label">First Name</label>
            <label class="ml-2 text-theme-6">*</label>
            <input id="profile-form-1" type="text" class="form-control" placeholder="name..." :disabled="isUpdating" v-model="firstName" />
            <label class="mt-2 form-label text-theme-6" v-if="$helpers.isNull(firstName)">
              จำเป็นต้องกรอกข้อมูล
            </label>
          </div>
        </div>
        <div class="col-span-12 xl:col-span-6">
          <div class="mt-3 xl:mt-0">
            <label id="profile-form-2" class="form-label">Last Name</label>
            <label class="ml-2 text-theme-6">*</label>
            <input id="profile-form-2" type="text" class="form-control" placeholder="name..." :disabled="isUpdating" v-model="lastName" />
            <label class="mt-2 form-label text-theme-6" v-if="$helpers.isNull(lastName)">
              จำเป็นต้องกรอกข้อมูล
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="justify-end p-5 mt-3 border-t border-gray-200 dark:border-dark-5">
      <div class="mt-3 mb-2 alert show alert-outline-warning"  role="alert">
        <div class="flex items-center">
          <AlertCircleIcon class="mr-2 " style="align-self: start;height: 32px;width: 32px;" />
          <div class="inline-block ">
            คุณได้ทำการตรวจสอบข้อมูล User เรียบร้อยแล้วหลังจากการแก้ไข กรุณาใส่
            <span class="p-1 text-theme-27">'Password'</span>
            ของคุณลงในช่องด้านล่างเพื่อยืนยันตัวตน
          </div>
        </div>
        <div class="items-center mt-5" style="text-align: -webkit-center;">
          <div class="w-64 text-center input-group " >
            <div :class="{ disabled : isUpdating }" class="input-group-text ">
              <LockIcon class="block w-3 mx-auto" />
            </div>
            <input id="profile-form-3" :type="isShowPassword ? 'text' : 'password'" :disabled="isUpdating"
              @change="onPasswordChanged"  autocomplete="off"
              placeholder="Password" v-model.trim="password" class="border-gray-300 form-control" aria-label="Password"
              aria-describedby="input-group-password" />
            <div div id="input-group-password " class="cursor-pointer input-group-text" :class="isUpdating ? 'disabled' : ''" :disabled="isUpdating"
              @click="isShowPassword = !isShowPassword">
              <EyeOffIcon v-if="isShowPassword" class="block w-3 mx-auto " />
              <EyeIcon v-else class="block w-3 mx-auto " />
            </div>
          </div>
        </div>
      </div>
      <div class="flex col-span-12 mt-5 -mb-3 text-theme-6" v-if="!$helpers.isNull(errorMessage)">
        <img :src="imgError" class="w-6 h-6 mr-2 " />
        {{ errorMessage }}
      </div>
      <div class="mt-7">
        <button type="button" class="mr-auto btn btn-primary" @click="onUpdateClicked"
                :disabled="isUpdating || isValidateData() || !isValidateRefData()">
          <LoadingIcon v-if="isUpdating"  icon="oval" class="w-6 h-6 mr-2 text-center"  />
           {{ isUpdating ? 'Updating' : 'Update' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { authService } from '@/services'
import imgError from '@/assets/images/Error32.png'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update'],
  data() {
    return {
      imgError,
      errorMessage: null,
      isUpdating: false,
      firstName: null,
      lastName: null,
      password: null,
      isShowPassword: false
    }
  },
  watch: {
    password() {
      this.isValidatePassword()
    },
    firstName() {
      this.isValidatePassword()
    },
    lastName() {
      this.isValidatePassword()
    }
  },
  methods: {
    isValidatePassword() {
      if (this.isValidateRefData() && !this.$helpers.isNull(this.password)) {
        this.errorMessage = null
      } else {
        if (this.$helpers.isNull(this.password)) {
          this.errorMessage = null
        } else {
          this.errorMessage = 'ข้อมูลที่มีอยู่นี้เป็นข้อมูลเดิม กรุณาต้องทำการเปลี่ยนแปลงข้อมูลก่อนถึงจะกดปุ่ม Update ได้'
        }
      }
    },
    onPasswordChanged() {
      this.isValidateRefData()
    },
    isValidateRefData() {
      return this.firstName !== this.modelValue.firstName ||
             this.lastName !== this.modelValue.lastName
    },
    isValidateData() {
      return this.$helpers.isNull(this.firstName) ||
             this.$helpers.isNull(this.lastName) ||
             this.$helpers.isNull(this.password)
    },
    async onUpdateClicked() {
      this.errorMessage = null
      this.isUpdating = true
      try {
        const payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password
        }
        const res = await authService.updateProfile(this.modelValue.id, payload)
        if (this.$helpers.isNull(res?.statusCode)) {
          this.$helpers.showToastify('Profile updated successfully.')
          this.$emit('update')
        } else {
          this.errorMessage = res.message
        }
      } catch (error) {
        this.errorMessage = error.message
      } finally {
        this.isUpdating = false
      }
    }
  },
  mounted() {
    this.firstName = this.modelValue?.firstName
    this.lastName = this.modelValue?.lastName
  }
})
</script>
