<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Create New Credential</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div class="wizard flex flex-col lg:flex-row justify-center px-5 sm:px-20">
        <div class="intro-x lg:text-center flex items-center lg:block flex-1 z-10">
          <div class="w-10 h-10 rounded-full btn cursor-default"
            :class="step === 1 ? 'btn-primary' : 'text-gray-600 bg-gray-200 dark:bg-dark-1'">
            1
          </div>
          <div class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto"
            :class="step === 1 ? ' font-medium' : 'text-gray-700 dark:text-gray-600'">
            Setup Profile
          </div>
        </div>
        <div class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10">
          <div class="w-10 h-10 rounded-full btn cursor-default"
            :class="step === 2 ? 'btn-primary' : 'text-gray-600 bg-gray-200 dark:bg-dark-1'">
            2
          </div>
          <div class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto "
            :class="step === 2 ? ' font-medium' : 'text-gray-700 dark:text-gray-600'">
            Setup Secret Vault
          </div>
        </div>
        <div class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10">
          <div class="w-10 h-10 rounded-full btn  cursor-default"
            :class="step === 3 ? 'btn-primary' : 'text-gray-600 bg-gray-200 dark:bg-dark-1'">
            3
          </div>
          <div class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto"
            :class="step === 3 ? ' font-medium' : 'text-gray-700 dark:text-gray-600'">
            Confirm
          </div>
        </div>
        <div class="wizard__line hidden lg:block w-3/5 bg-gray-200 dark:bg-dark-1 absolute mt-5"></div>
      </div>
      <div v-if="step === 1" class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-3">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Bank</label>
            <label class="text-theme-6 ml-2">*</label>
            <div class="flex items-center">
              <TomSelect :disabled="isLoading" id="post-form-2" class="w-full" v-model="profileSetup.bank"
                placeholder="Select Bank">
                <option v-for="option in listBanks" :key="option.id" :value="option.id">
                  {{ `${option.bankGroupShortNameEn}-${option.bankShortName}|||${option.iconClass}` }}
                </option>
              </TomSelect>
              <LoadingIcon v-if="isLoading" icon="oval" class="text-center ml-3 w-6 h-6" />
            </div>
            <div class="text-theme-6 mt-2" v-if="profileSetup.bank === '' || profileSetup.bank === null">
              This field is required
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Credential Name</label>
            <label class="text-theme-6 ml-2">*</label>
            <input type="text" class="form-control" v-model="profileSetup.name" placeholder="Credential Name" />
            <div class=" text-theme-6 mt-2" v-if="profileSetup.name === '' || profileSetup.name === null">
              This field is required
            </div>
            <div class=" text-theme-6 mt-2" v-if="errorMessageProfileName !== '' && errorMessageProfileName !== null">
             {{ errorMessageProfileName }}
            </div>
          </div>
          <div class="intro-y col-span-12">
            <label for="input-wizard-4" class="form-label">Description</label>
            <textarea style="resize:none" class="form-control h-28 " v-model="profileSetup.description"
              placeholder="Description" />
          </div>
        </div>
      </div>
      <div v-if="step === 2" class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-3">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Username</label>
            <label class="text-theme-6 ml-2">*</label>
            <div class="input-group border-gray-300 ">
              <div id="input-group-username" class="input-group-text">
                <UserIcon class="w-3 block mx-auto" />
              </div>
              <input type="text" v-model.trim="secretVaultSetup.username" placeholder="Username"
                class=" border-gray-300  form-control " aria-label="Username" aria-describedby="input-group-username" />
            </div>
            <div class=" text-theme-6 mt-2" v-if="secretVaultSetup.username === '' || secretVaultSetup.username === null">
              This field is required
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Password</label>
            <label class="text-theme-6 ml-2">*</label>
            <div class="input-group  border-gray-300 ">
              <div id="input-group-password " class="input-group-text ">
                <LockIcon class="w-3 block mx-auto" />
              </div>
              <input :type="isShowPassword ? 'text' : 'password'" placeholder="Password"
                v-model.trim="secretVaultSetup.password" class=" form-control border-gray-300 " aria-label="Password"
                aria-describedby="input-group-password" />
              <div id="input-group-password " class="input-group-text cursor-pointer"
                @click="isShowPassword = !isShowPassword">
                <EyeOffIcon v-if="isShowPassword" class="w-3 block mx-auto " />
                <EyeIcon v-else class="w-3 block mx-auto " />
              </div>
            </div>
            <div class=" text-theme-6 mt-2" v-if="secretVaultSetup.password === '' || secretVaultSetup.password === null">
              This field is required
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="isHaveOptional()">
            <label class="form-label">Company ID / Company Code</label>
            <label class="text-theme-6 ml-2">*</label>
            <input type="text" class="form-control h-10 " style="margin-top:1px" v-model="secretVaultSetup.company"
              placeholder="Company ID / Company Code" />
              <div class=" text-theme-6 mt-2" v-if="secretVaultSetup.company === '' || secretVaultSetup.company === null">
                This field is required
              </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" style="z-index: 50;">
            <label class="form-label ">Password Expiration Date</label>
            <label class="form-label ml-2  text-yellow-400">** ระบบแจ้งเตือนก่อนหมดอายุ 7 วัน</label>
            <litepie-datepicker :formatter="dateFormatter" :auto-apply="false" readonly as-single
              v-model="profileSetup.expiryDateReminder" />
            <div class="text-theme-6 mt-2" v-if="isExpiryDateFormat">
              Unable to select a date backwards from the current date.
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 3" class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
        <div class="grid grid-cols-12 gap-4 gap-y-5 -mt-3">
          <div class="intro-y col-span-12">
            <div class="font-medium text-base" style="text-decoration: underline">Profile</div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6 ">
            <label class="form-label">Bank</label>
            <div class="form-control cursor-not-allowed readonly"
              style="text-overflow: ellipsis;overflow: hidden; white-space: nowrap;">
              <i :class="listBanks?.find(_ => _.id == profileSetup.bank)?.iconClass" />
              <span class="ml-3">
                {{ listBanks?.find(_ => _.id == profileSetup.bank)?.bankGroupShortNameEn }} -
                {{ listBanks?.find(_ => _.id == profileSetup.bank)?.bankShortName }}
              </span>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Credential Name</label>
            <input type="text" class="form-control" readonly v-model="profileSetup.name" />
          </div>
          <div class="intro-y col-span-12">
            <label for="input-wizard-4" class="form-label">Description</label>
            <textarea style="resize:none" readonly class="form-control h-28 " v-model="profileSetup.description" />
          </div>
          <div class="intro-y col-span-12">
            <div class="font-medium text-base" style="text-decoration: underline">Secret Vault</div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Username</label>
            <div class="input-group border-gray-300 ">
              <div id="input-group-username" class="input-group-text">
                <UserIcon class="w-3 block mx-auto" />
              </div>
              <input readonly type="text" v-model.trim="secretVaultSetup.username" class=" border-gray-300  form-control "
                aria-label="Username" aria-describedby="input-group-username" />
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Password</label>
            <div class="input-group  border-gray-300 ">
              <div id="input-group-password " class="input-group-text ">
                <LockIcon class="w-3 block mx-auto" />
              </div>
              <input readonly :type="isShowPassword ? 'text' : 'password'" v-model.trim="secretVaultSetup.password"
                class=" form-control border-gray-300 " aria-label="Password" aria-describedby="input-group-password" />
              <div id="input-group-password " class="input-group-text cursor-pointer"
                @click="isShowPassword = !isShowPassword">
                <EyeOffIcon v-if="isShowPassword" class="w-3 block mx-auto " />
                <EyeIcon v-else class="w-3 block mx-auto " />
              </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="isHaveOptional()">
            <label class="form-label">Company ID / Company Code</label>
            <input type="text" class="form-control" readonly v-model="secretVaultSetup.company" />
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" style="z-index: 50;">
            <label class="form-label ">Password Expiration Date</label>
            <label class="form-label ml-2  text-yellow-400">** ระบบแจ้งเตือนก่อนหมดอายุ 7 วัน</label>
            <input type="text" class="form-control" readonly v-model="profileSetup.expiryDateReminder" />
          </div>
        </div>
      </div>
      <div v-if="step === 3" class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
        <div class="grid grid-cols-12 gap-4 gap-y-5 -mt-3">
          <div class="intro-y col-span-12">
            <div class="alert show mb-2" :class="isDarkMode ? 'alert-outline-warning' : 'alert-warning-soft'"
              role="alert">
              <div class="flex items-center">
                <AlertCircleIcon class="mr-2 " style="align-self: start;height: 32px;width: 32px;" />
                <div class=" inline-block">
                  คุณได้ทำการตรวจสอบข้อมูล Login โดยตรวจสอบจากเว็บธนาคารที่คุณเลือก
                  <span class="p-1  text-theme-27">'{{ findBankName(profileSetup?.bank)?.bankGroupShortNameEn }} - {{ findBankName(profileSetup?.bank)?.bankShortName }}'</span>
                  กรุณาพิมพ์คำว่า
                  <span class="p-1  text-theme-27">'Confirm'</span>
                  ลงใน ช่องด้านล่าง.
                </div>
              </div>
              <div class="items-center text-center mt-5">
                <input type="text" class="form-control w-64 text-center" :readonly="isCreating"
                  :class="txtConfirm === null || txtConfirm === '' ? ' border-red-700 dark:border-red-500' : ''"
                  v-model="txtConfirm" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y px-5 sm:px-20 -mb-5" v-if="!isStepChange">
        <div class="col-span-12 flex text-theme-6 mt-2" v-if="!$helpers.isNull(errorMessage)">
          <img :src="imgError" class=" w-6 h-6 mr-2" />
          {{ errorMessage }}
        </div>
        <div class="col-span-12 flex items-center justify-center sm:justify-end mt-5 ">
          <button class="btn btn-secondary w-24" :disabled="isCreating" v-if="step !== 1"
            @click="onPreviousNextClicked(-1)">Previous</button>
          <button class="btn btn-primary ml-2" style="min-width: 6rem;" v-if="step === 3"
            :disabled="txtConfirm === null || txtConfirm === '' || isCreating" @click="onCreateClicked($event)">
            <div class="flex flex-col sm:flex-row justify-end items-center">
              <div class="text-center">
                {{ isCreating ? "Creating" : "Create" }}
              </div>
              <LoadingIcon v-if="isCreating" icon="oval" class="text-center ml-3 w-6 h-6" />
            </div>
          </button>
          <button class="btn btn-primary ml-2" style="min-width: 6rem;" v-else :disabled="isDisableNext || isCreating || isExpiryDateFormat"
            @click="onPreviousNextClicked(1)">
            <div class="text-center">
              {{ isCreating ? "Waiting" : "Next" }}
            </div>
            <LoadingIcon v-if="isCreating" icon="oval" class="text-center ml-3 w-6 h-6"  />
          </button>
        </div>
      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>

import { bankService, cloudService } from '@/services'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from 'vue-router'
import imgError from '@/assets/images/Error32.png'
import { useStore } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      imgError,
      step: 1,
      router: null,
      isStepChange: false,
      isShowPassword: false,
      isExpiryDateFormat: false,
      isLoading: false,
      isCreating: false,
      isDisableNext: true,
      txtConfirm: null,
      errorMessage: null,
      errorMessageProfileName: null,
      notificationMessage: {
        title: '',
        body: '',
        isError: false
      },
      profileSetup: {
        bank: null,
        name: null,
        description: null,
        expiryDateReminder: []
      },
      secretVaultSetup: {
        username: null,
        password: null,
        company: null
      },
      dateFormatter: {
        date: 'YYYY-MM-DD',
        month: 'MMM'
      },
      confirm: 'Confirm',
      listBanks: []
    }
  },
  watch: {
    step(val, oldVal) {
      this.nextUpdated()
      this.isStepChange = true
      this.$nextTick(() => {
        this.isStepChange = false
      })
    },
    profileSetup: {
      deep: true,
      handler(val, oldVal) {
        if (val.expiryDateReminder?.length > 0) {
          const currentDateTime = new Date()
          const valDate = new Date(val.expiryDateReminder[0])
          const compareValDate = new Date(valDate.getFullYear(), valDate.getMonth(), valDate.getDate())
          const compareDate = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate())
          if (compareValDate <= compareDate) {
            this.isExpiryDateFormat = true
          } else {
            this.isExpiryDateFormat = false
          }
        } else {
          this.isExpiryDateFormat = false
        }
        this.nextUpdated()
        this.isHaveOptional()
      }
    },
    secretVaultSetup: {
      deep: true,
      handler(val, oldVal) {
        this.nextUpdated()
      }
    }
  },
  methods: {
    findBankName(val) {
      return this.listBanks?.find(_ => _.id == val)
    },
    isHaveOptional() {
      const item = this.listBanks.find((x) => x.id == this.profileSetup.bank)
      if (item?.fieldamout > 2) {
        return true
      } else {
        this.secretVaultSetup.company = null
        return false
      }
    },
    async onPreviousNextClicked(value) {
      if (this.step === 1) {
        this.errorMessageProfileName = null
        this.isCreating = true
        const res = await this.getSecretVaultByName(this.profileSetup.name)
        this.isCreating = false
        if (res !== null && res !== undefined && res !== '') {
          this.errorMessageProfileName = `The name '${this.profileSetup.name}' cannot be used because this name already exists in the system.`
          return
        }
      }
      this.step += value
    },
    async onCreateClicked(event) {
      if (this.txtConfirm === this.confirm) {
        this.isCreating = true
        let tempExpiryDate = null
        if (this.profileSetup?.expiryDateReminder.length > 0) {
          if (this.profileSetup.expiryDateReminder[0] !== '-') {
            tempExpiryDate = this.$filters.formatDateTimeToServer(this.profileSetup.expiryDateReminder[0])
          }
        }
        const payload = {
          id: 0,
          userid: 0,
          uuid: uuidv4(),
          name: this.profileSetup.name,
          desc: this.profileSetup.description,
          bankId: this.profileSetup.bank,
          expiryDate: tempExpiryDate
        }
        const keyvalue = {
          username: this.secretVaultSetup.username,
          password: this.secretVaultSetup.password,
          companyid: this.secretVaultSetup.company
        }
        try {
          await this.addSecretVaultToCloud(payload.uuid, JSON.stringify(keyvalue))
          if (this.isEditing === true) {
            await bankService.UpdateSecretVault(payload)
          } else {
            await bankService.AddSecretVault(payload)
          }
          event.preventDefault()
          this.$helpers.showToastify('Successfully', this.isEditing ? 'updated Credential!' : 'Created credential!', false)
          this.router.push({
            name: 'credentials'
          })
        } catch (err) {
          this.isCreating = false
          this.errorMessage = err.message
        }
      } else {
        this.$helpers.showToastify('Invalid "Confirm" text!', 'Please enter again correctly.', true)
      }
    },
    nextUpdated() {
      this.txtConfirm = null
      if (this.step === 1) {
        this.isDisableNext = (this.profileSetup.bank === '' || this.profileSetup.bank === null) ||
          (this.profileSetup.name === '' || this.profileSetup.name === null)
      } else if (this.step === 2) {
        this.isDisableNext = (this.secretVaultSetup.username === '' || this.secretVaultSetup.username === null) ||
          (this.secretVaultSetup.password === '' || this.secretVaultSetup.password === null) ||
          (this.isHaveOptional() ? (this.secretVaultSetup.company === '' || this.secretVaultSetup.company === null) : false)
      }
    },
    async getAllBank() {
      const res = await bankService.GetBankName()
      this.listBanks = res
      this.profileSetup.bank = res[0]?.id
    },
    async getSecretVaultByName(val) {
      return await bankService.GetSecretVaultByName({ name: val })
    },
    async addSecretVaultToCloud(keyname, keyvalue) {
      const payload = {
        keyname: keyname,
        keyvalue: keyvalue
      }
      await cloudService.SetByKey(payload)
    }
  },
  computed: {
    isDarkMode() {
      const store = useStore()
      return store.getters['main/darkMode']
    }
  },
  created() {
    this.router = useRouter()
  },
  async mounted() {
    this.isLoading = true
    await this.getAllBank()
    this.isLoading = false
  }
})
</script>
