<template>
  <div>
    <div class="flex items-center mt-8 intro-y">
      <h2 class="mr-auto text-lg font-medium">Profile</h2>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Profile Menu -->
      <div class="flex flex-col-reverse col-span-12 lg:col-span-4 xxl:col-span-3 lg:block">
        <div class="mt-5 intro-y box">
          <div class="relative flex items-center p-5">
            <div class="w-12 h-12 image-fit">
              <img alt="Icewall Tailwind HTML Admin Template" class="rounded-full"
                :src="require(`@/assets/images/${$f()[0].photos[0]}`)" />
            </div>
            <div class="ml-4 mr-auto">
              <div class="text-base font-medium">
                {{ profile?.firstName }} {{ profile?.lastName }}
              </div>
            </div>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <div class="-mt-5">
              <a v-for="(item, index) in listProfileMenu" :key="index" @click="onProfileMenuClicked(item)"
                 :class="selectProfileMenu === item ? 'text-theme-1 dark:text-theme-10 font-medium ' :''" class="flex items-center mt-5" href="#">
             <component class="w-4 h-4 mr-2" :is="item?.componentIcon"/>{{ item?.name }}
             <Tippy v-if="item.id == 3 && profile?.isEmailVerified === false"  class="ml-2 -mt-1 text-yellow-400 tooltip"
                    content="Email has not been verified.">
              <AlertCircleIcon class="w-4 h-4" />
            </Tippy>
           </a>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Profile Menu -->
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
        <component v-model="profile" @update="onProfileUpdated" :is="selectProfileMenu?.componentView"/>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { authService } from '@/services'
import ProfileInformationView from '@/views/corgio/profile-information/Main.vue'
import ProfileSettingView from '@/views/corgio/profile-setting/Main.vue'
import ProfileEmailView from '@/views/corgio/profile-email/Main.vue'
import ProfilePasswordView from '@/views/corgio/profile-password/Main.vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  components: {
    ProfileInformationView,
    ProfileSettingView,
    ProfileEmailView,
    ProfilePasswordView
  },
  computed: {
    ...mapGetters({
      userProfile: 'main/userProfile'
    })
  },
  data: () => ({
    profile: null,
    listProfileMenu: [],
    selectProfileMenu: null
  }),
  created() {
    this.listProfileMenu.push({
      id: 1,
      name: 'Personal Information',
      componentIcon: 'ActivityIcon',
      componentView: 'ProfileInformationView'
    })
    this.listProfileMenu.push({
      id: 2,
      name: 'User Setting',
      componentIcon: 'SettingsIcon',
      componentView: 'ProfileSettingView'
    })
    this.listProfileMenu.push({
      id: 3,
      name: 'Email Setting',
      componentIcon: 'MailIcon',
      componentView: 'ProfileEmailView'
    })
    this.listProfileMenu.push({
      id: 4,
      name: 'Change Password',
      componentIcon: 'LockIcon',
      componentView: 'ProfilePasswordView'
    })
  },
  async mounted() {
    this.profile = { ...this.userProfile }
    this.setDefaultProfileMenu()
  },
  methods: {
    setDefaultProfileMenu() {
      this.selectProfileMenu = this.listProfileMenu.find(_ => _.id === 1)
    },
    async onProfileUpdated() {
      const tempProfile = await authService.GetUserProfile(this.profile.id)
      Object.keys(tempProfile).forEach(_ => {
        this.profile[_] = tempProfile[_]
      })
      this.$store.dispatch('main/setUserProfile', this.profile)
      this.setDefaultProfileMenu()
    },
    onProfileMenuClicked(item) {
      this.selectProfileMenu = item
    }
  }
})
</script>
