<template>
  <div class="intro-y box mt-5">
    <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <h2 class="font-medium text-base mr-auto">Personal Information</h2>
    </div>
    <div class="p-5">
      <div class="grid grid-cols-12 gap-x-5 mb-4">
        <div class="col-span-12 xl:col-span-6">
          <div>
            <label class="form-label">First Name</label>
            <div class="form-control disabled"  >
              {{ modelValue.firstName }}
            </div>
          </div>
          <div class="mt-3 ">
            <label class="form-label">Email</label>
            <div class="form-control disabled"  >
              {{ modelValue.email }}
            </div>
          </div>
        </div>
        <div class="col-span-12 xl:col-span-6">
          <div class="mt-3 xl:mt-0">
            <label class="form-label">Last Name</label>
            <div class="form-control disabled"  >
              {{ modelValue.lastName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  }
})
</script>
