import HttpRequest from './HttpRequest'

class AuthService extends HttpRequest {
  constructor() {
    super(window.__env.api.apiAuth + 'auth-service')
  }

  async Login(payload) {
    await this.delay(1000)
    const res = await this.post('login', payload)
    const { data } = res
    return data
  }

  async LoginByToken(payload) {
    const res = await this.post('loginbytoken', payload)
    const { data } = res
    return data
  }

  async GetProfile() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('profile', {})
    const { data } = res
    return data
  }

  async GetUserProfile(id) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get(`user/${id}`)
    const { data } = res
    return data
  }

  async getPolicyPassword() {
    const res = await this.get('policypassword')
    const { data } = res
    return data
  }

  async updatePassword(id, payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    await this.delay(500)
    const res = await this.post(`user/${id}/password`, payload)
    const { data } = res
    return data
  }

  async updateEmail(id, payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    await this.delay(500)
    const res = await this.put(`user/${id}/email`, payload)
    const { data } = res
    return data
  }

  async updateProfile(id, payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    await this.delay(500)
    const res = await this.put(`user/${id}/profile`, payload)
    const { data } = res
    return data
  }

  async register(payload) {
    await this.delay(1000)
    const res = await this.post('user', payload)
    const { data } = res
    return data
  }

  async verifyRegister(payload) {
    await this.delay(1000)
    const res = await this.get('verify/register', payload)
    const { data } = res
    return data
  }

  async verifyResetPassword(payload) {
    await this.delay(1000)
    const res = await this.get('verify/reset-password', payload)
    const { data } = res
    return data
  }

  async verifyEmail(payload) {
    await this.delay(1000)
    const res = await this.get('verify/email', payload)
    const { data } = res
    return data
  }

  async ForgotPassword(payload) {
    await this.delay(1000)
    const res = await this.post('forgot-password', payload)
    const { data } = res
    return data
  }

  async resetPassword(payload) {
    await this.delay(1000)
    const res = await this.post('reset-password', payload)
    const { data } = res
    return data
  }

  async getAccountFromProvider() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('getaccountfromprovider', {})
    const { data } = res
    return data
  }

  async UpdateListBankStatus(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post(
      'providerconnect/0/updatelistbankstatus',
      payload
    )
    const { data } = res
    return data
  }

  async getProviderConnectFromUser() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('providerconnect/0/getbyuserid', {})
    const { data } = res
    return data
  }

  async getListProviderConnectFromUser() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('providerconnect/0/getlistbyuserid', {})
    const { data } = res
    return data
  }

  async ValidatePassword(payload) {
    await this.delay(1000)
    const res = await this.post('validatepassword', payload)
    const { data } = res
    return data
  }

  async delay(milliseconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds)
    })
  }
}

export default AuthService
