import HttpRequest from './HttpRequest'

class CloudService extends HttpRequest {
  constructor() {
    super(window.__env.api.apiCloud + 'cloud-service')
  }

  async SetByKey(payload) {
    await this.delay(1000)
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post('setbykey', payload)
    const { data } = res
    return data
  }

  async DeleteByKey(key) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.delete(`/${key}`)
    const { data } = res
    return data
  }

  async GetByKey(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('getbykey', payload)
    const { data } = res
    return data
  }

  async DownloadFile(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.getBlob('storage/downloadfile', payload)
    const { data } = res
    return data
  }

  async DownloadMultiFile(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.getBlob('storage/downloadmultifile', payload)
    const { data } = res
    return data
  }

  async GetFileList(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    await this.delay(1000)
    const res = await this.get('storage/getlistfile', payload)
    const { data } = res
    return data
  }

  async GetFileListFilter(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    await this.delay(1000)
    const res = await this.get('storage/getlistfilefilter', payload)
    const { data } = res
    return data
  }

  async delay(milliseconds) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds)
    })
  }
}

export default CloudService
