import * as types from './mutation-types'

const state = () => {
  return {
    darkMode: true,
    currentCurrency: localStorage.getItem('currentCurrency'),
    pageName: '',
    quotaData: {
      totalUsedQuota: 0,
      totalQuota: 0,
      quotaExpireDate: ''
    },
    providerConnect: [],
    userProfile: null
  }
}

// getters
const getters = {
  darkMode: state => {
    if (state.darkMode) {
      return state.darkMode
    }
    const darkMode = localStorage.getItem('CURRENT_DARKMODE') && localStorage.getItem('CURRENT_DARKMODE') !== '' ? JSON.parse(localStorage.getItem('CURRENT_DARKMODE')) : undefined
    if (darkMode) {
      return darkMode
    }
    return state.darkMode
  },
  userProfile: state => {
    if (state?.userProfile === null || state?.userProfile === undefined || state?.userProfile === '') {
      const userProfile = localStorage.getItem('USER_PROFILE')
      if (userProfile === null || userProfile === undefined || userProfile === '') {
        return null
      } else {
        return JSON.parse(userProfile)
      }
    } else {
      return state.userProfile
    }
  },
  currentCurrency: state => state.currentCurrency,
  currentPageName: state => state.pageName,
  currentQuotaData: (state) => {
    if (state.quotaData.totalQuota !== 0) {
      return state.quotaData
    }
    if (localStorage.getItem('CURRENT_QUOTA') && localStorage.getItem('CURRENT_QUOTA') !== '') {
      return JSON.parse(localStorage.getItem('CURRENT_QUOTA'))
    }
    return state.quotaData
  },
  haveProvider: (state) => {
    if (state.providerConnect && state.providerConnect.length > 0) {
      return true
    }
    const providers = localStorage.getItem('CURRENT_PROVIDER') && localStorage.getItem('CURRENT_PROVIDER') !== '' ? JSON.parse(localStorage.getItem('CURRENT_PROVIDER')) : undefined
    if (providers && providers.length > 0) {
      return true
    }
    return false
  },
  isSyncProvider: (state) => {
    if (state.providerConnect && state.providerConnect.length > 0) {
      return Boolean(state.providerConnect[0]?.isSync) ?? false
    }
    const providers = localStorage.getItem('CURRENT_PROVIDER') && localStorage.getItem('CURRENT_PROVIDER') !== '' ? JSON.parse(localStorage.getItem('CURRENT_PROVIDER')) : undefined
    if (providers && providers.length > 0) {
      return Boolean(providers[0]?.isSync) ?? false
    }
    return false
  },
  provider: (state) => {
    if (state.providerConnect && state.providerConnect.length > 0) {
      return state.providerConnect
    }
    const providers = localStorage.getItem('CURRENT_PROVIDER') && localStorage.getItem('CURRENT_PROVIDER') !== '' ? JSON.parse(localStorage.getItem('CURRENT_PROVIDER')) : undefined
    if (providers && providers.length > 0) {
      return providers
    }
    return []
  }
}

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode })
  },
  setCurrentCurrency({ commit }, currentCurrency) {
    commit('SET_CURRENT_CURRENCY', { currentCurrency })
  },
  setPageName({ commit }, currentPageName) {
    commit('SET_CURRENT_PAGENAME', { currentPageName })
  },
  setQuotaData({ commit }, quotaData) {
    commit('SET_CURRENT_QUOTA', { quotaData })
  },
  setProvider({ commit }, providerData) {
    commit('SET_PROVIDER', { providerData })
  },
  setUserProfile({ commit }, userProfileData) {
    commit('SET_USER_PROFILE', { userProfileData })
  }
}

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    localStorage.setItem('SET_CURRENT_DARKMODE', JSON.stringify(darkMode))
    state.darkMode = darkMode
  },
  'SET_CURRENT_CURRENCY'(state, { currentCurrency }) {
    localStorage.setItem('currentCurrency', currentCurrency)
    state.currentCurrency = currentCurrency
  },
  'SET_CURRENT_QUOTA'(state, { quotaData }) {
    localStorage.setItem('CURRENT_QUOTA', JSON.stringify(quotaData))
    state.quotaData = quotaData
  },
  'SET_CURRENT_PAGENAME'(state, { currentPageName }) {
    localStorage.setItem('SET_CURRENT_PAGENAME', JSON.stringify(currentPageName))
    state.pageName = currentPageName
  },
  'SET_PROVIDER'(state, { providerData }) {
    localStorage.setItem('CURRENT_PROVIDER', JSON.stringify(providerData))
    state.providerConnect = providerData
  },
  'SET_USER_PROFILE'(state, { userProfileData }) {
    localStorage.setItem('USER_PROFILE', JSON.stringify(userProfileData))
    state.userProfile = userProfileData
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
