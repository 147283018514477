<!-- eslint-disable no-unused-vars -->
<template>
  <div>
    <div class="intro-y grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12 lg:col-span-12">
        <div class="intro-y box">
          <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto">คำถามที่พบบ่อย</h2>
          </div>
          <div id="boxed-accordion" class="p-5">
            <div class="preview">
              <div id="faq-accordion-1" class="accordion accordion-boxed">
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-1" class="accordion-header">
                    <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-accordion-collapse-1"
                    aria-expanded="false"
                    aria-controls="faq-accordion-collapse-1">
                      <div class="flex flex-col sm:flex-row ">
                        <span>1. มีธนาคารอะไรบ้างที่รองรับการทำงานของระบบ StatementPro</span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-1"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-1"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body text-white dark:text-white leading-relaxed p-3" >
                      <div class="intro-x">
                        <span> ปัจจุบันธนาคารที่รองรับ ประกอบไปด้วย</span>
                        <div class="mt-5">
                          <div style="border-top-left-radius: 10px;border-top-right-radius: 10px;"
                                class=" inbox__item inline-block sm:block text-gray-700 dark:text-gray-500 bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1">
                            <div class="flex px-5 py-3 ">
                              <div class="w-96 items-center mr-5 text-white dark:text-white" >
                                ธนาคาร
                              </div>
                              <div class="w-96 items-center mr-5 text-white dark:text-white" >
                                ชื่อเว็บไซต์
                              </div>
                            </div>
                          </div>
                        </div>
                       <div class="inbox box overflow-x-auto sm:overflow-x-visible">
                         <div class="intro-x" v-for="(item, index) in listBank" :key="index">
                           <div class="inbox__item--active  inbox__item inline-block sm:block text-gray-700 dark:text-gray-500 bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1">
                             <div class="flex px-5 py-3">
                               <div class="w-96 items-center mr-5" >
                                {{ item.bank }}
                               </div>
                               <div class="w-96 items-center mr-5 " >
                                {{ item.site }}
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-2" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-2"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-2">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          2. ลูกค้าสามารถเพิ่มธนาคารที่ไม่ได้อยู่ใน List รายการที่รองรับการทำงานได้หรือไม่
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-2"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-2"
                    data-bs-parent="#faq-accordion-1" >
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                        ขณะนี้ยังไม่สามารถเพิ่มธนาคารจากใน List ด้านบนได้ แต่ในอนาคตทาง Lightwork จะมีการพัฒนาในส่วนของธนาคารอื่นๆ ลูกค้าสามารถแจ้งความประสงค์การใช้งานธนาคารอื่นๆ ของลูกค้ากลับมาได้ ผ่านทางหน้าเว็บไซต์ของ Lightwork เพื่อใช้ในการพัฒนาในอนาคต
                    </div>
                  </div>
                </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-3" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-3"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-3">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          3. บัญชีประเภทใดบ้างที่รองรับการทำงานของระบบ StatementPro
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-3"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-3"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                          สามารถทำได้ทั้งบัญชีออมทรัพย์, กระแสรายวัน, ฝากประจำ
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-4" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-4"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-4">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          4. หากต้องการให้ระบบ StatementPro ทำงานจะต้องเตรียมข้อมูลอะไรบ้าง
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-4"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-4"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-y ">
                        <span> ข้อมูลที่จะต้องเตรียม เพื่อเชื่อมข้อมูล Statement ประกอบด้วย </span>
                        <table class="table mt-5">
                          <thead>
                            <tr class="bg-gray-700 dark:bg-dark-1 text-white">
                              <th class="intro-x border-b-2 dark:border-dark-5 border-gray-600 whitespace-nowrap">
                                ลำดับ
                              </th>
                              <th class="intro-x border-b-2 dark:border-dark-5 border-gray-600 whitespace-nowrap">
                                ข้อมูลส่วนที่กรอกเข้าระบบ StatementPro
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in listLightworkData" :key="index"
                                class="intro-x bg-gray-700 dark:bg-dark-1 text-white">
                              <td class="border-b border-gray-600">{{item.id}}</td>
                              <td class="border-b border-gray-600">{{item.text}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-5" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-5"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-5">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          5. จำเป็นต้องส่งข้อมูล Login (เช่น Username, Password) ให้กับเจ้าหน้าที่หรือไม่
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-5"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-5"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                        ไม่ต้องส่งข้อมูล Login ให้กับเจ้าหน้าที่ ลูกค้าสามารถกรอกข้อมูล Login เข้าสู่ระบบ StatementPro ด้วยตัวเองได้เลย
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-6" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-6"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-6">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          6. ข้อมูล Login ที่นำไปใช้กับระบบ StatementPro สามารถใช้ User เดียวกันกับที่ลูกค้าใช้อยู่ในปัจจุบันได้หรือไม่
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-6"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-6"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                        • ไม่ควรใช้ข้อมูล Login เดียวกันกับที่ลูกค้าใช้งานอยู่ เนื่องจากเว็บไซต์ธนาคารส่วนใหญ่จะมีระบบป้องกันการ Login เข้าใช้งานพร้อมกัน หากเข้าในเวลาเดียวกัน มีความเสี่ยงที่จะทำให้ผู้ที่ใช้งาน Login นั้นๆ ไม่สามารถเข้าสู่ระบบได้ ทำให้เกิดความผิดพลาดในการดึงข้อมูล Statement
                      </div>
                      <div class="mt-3 intro-x">
                        • แนะนำให้สร้าง Username แยกสำหรับระบบ StatementPro โดยให้มีสิทธิ์แค่ดูข้อมูล ได้เท่านั้น (View only) หรือหากไม่สามารถสร้างได้จำเป็นต้องใช้ Username เดียวกันทางลูกค้าจะต้องไม่ทำการ Login ในช่วงเวลาที่ระบบ StatementPro ทำงานอยู่
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-7" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-7"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-7">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          7. ลูกค้าจะทราบได้อย่างไร ว่ารหัสผ่านจะไม่ถูกนำไปใช้หรือเผยแพร่
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-7"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-7"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                      การเก็บข้อมูล Login ไม่ว่าจะเป็น Username/Password ระบบ StatementPro มีการเก็บโดย Azure Key Vault ซึ่งเป็นบริการ Cloud โดย Microsoft สำหรับการจัดเก็บและเข้าถึงข้อมูลที่เป็นความลับ มีความปลอดภัยสูงในการเข้าถึงข้อมูล เหมาะกับการเก็บข้อมูล Password ที่ต้องการ Security สูง
                    </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-8" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-8"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-8">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          8. ลูกค้าจะทราบได้อย่างไรว่า การเข้าถึง Bank Account ของระบบ StatementPro จะไม่ถูกนำไปทำธุรกรรมทางการเงินที่ก่อให้เกิดความเสียหาย
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-8"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-8"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                       <div class="intro-x">
                        ลูกค้าสามารถสร้าง User เพื่อนำมาใช้กับระบบ StatementPro โดย Add Permission (สิทธิ์การเข้าถึง) ให้ทำได้เฉพาะ อ่านข้อมูล Statement เท่านั้นได้ ทำให้มั่นใจได้ว่า User นี้ไม่มีสิทธิ์การทำงานอื่นๆ นอกเหนือจากการอ่านข้อมูล ทั้งนี้การตั้งค่าของระบบจะทำแค่เชื่อมข้อมูลส่วน Statement/Bill Payment เท่านั้น ไม่มีการตั้งค่าส่วนที่เกี่ยวข้องกับการโอนเงินทั้งเข้าและออกแต่อย่างใด
                       </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-9" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-9"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-9">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          9. ลูกค้าต้องการเพิ่มข้อมูลเลขบัญชีธนาคารเพื่อให้ระบบ StatementPro ทำงานได้อย่างไร
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-9"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-9"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                        สามารถทำการ เพิ่มข้อมูล Bank Account ได้ดังนี้
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          1. เข้าไปที่หน้าของ Lightwork ไปที่เมนู: Bank Management จากนั้นกดปุ่ม New Bank Accounts
                        </span>
                        <div class="thumbnail" style="height:500px" >
                           <img alt="random image" loading="lazy" :src="imgFaq91" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          2. จะพบ Form ให้กรอกข้อมูล Bank Account
                        </span>
                        <div class="thumbnail" style="height:460px" >
                           <img alt="random image" loading="lazy" :src="imgFaq92" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          3. เมื่อกรอกข้อมูลครบแล้วให้กดปุ่ม Create ก็จะสร้างข้อมูล Bank Account เสร็จเรียบร้อย
                        </span>
                        <div class="thumbnail" style="height:450px" >
                           <img alt="random image" loading="lazy" :src="imgFaq93" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-10" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-10"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-10">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          10. ลูกค้าสามารถ Set รหัสผ่านเองเมื่อมีการเพิ่มเลขบัญชีมาแล้วได้อย่างไร
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-10"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-10"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                        คุณสามารถ Set รหัสผ่านใหม่เองได้ โดยดำเนินการเพิ่มดังนี้
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          1.เข้าไปที่หน้าของ Lightwork แล้วไปที่เมนู: Credential กดปุ่ม Add Credential เพื่อสร้างข้อมูล Login ของธนาคาร
                        </span>
                        <div class="thumbnail" style="height:350px">
                           <img alt="random image" loading="lazy" :src="imgFaq101" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          2. จะพบ Form ขึ้นมา ให้กรอกรายละเอียดตามรูปด้านล่าง ส่วน Setup Profile
                        </span>
                      </div>
                      <div class="mt-3 intro-x text-center">
                        <span>
                          ส่วน Setup Profile
                        </span>
                        <div class="thumbnail" style="height:650px" >
                           <img alt="random image" loading="lazy" :src="imgFaq102" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x text-center">
                        <span>
                          ส่วน Setup Secret Vault
                        </span>
                        <div class="thumbnail" style="height:520px" >
                           <img alt="random image" loading="lazy" :src="imgFaq103" />
                        </div>
                      </div>
                      <div class="intro-x mt-3">
                        <span>
                          จากนั้นตรวจสอบข้อมูลอีกครั้ง และพิมพ์ Confirm ในช่องด้านล่าง จากนั้นกดปุ่ม Create ก็จะสร้างข้อมูลการ Login ของธนาคาร เสร็จเรียบร้อย
                        </span>
                        <div class="thumbnail" style="height:670px" >
                           <img alt="random image" loading="lazy" :src="imgFaq104" />
                        </div>
                      </div>
                      <div class="intro-x mt-3">
                        <span>
                          หลังจากสร้างข้อมูล Login ใน Credentials เรียบร้อยแล้ว ให้นำ Credential มาผูกข้อมูล Login กับ Account ของธนาคาร ดังนี้
                        </span>
                      </div>
                      <div class="intro-x mt-3">
                        <span>
                          1. ไปที่เมนู: Bank Management จากนั้นเลือก Bank Account ที่ต้องการผูกข้อมูล Login โดยกด Drop Down List ตามรูป
                        </span>
                        <div class="thumbnail"  >
                           <img alt="random image" loading="lazy" :src="imgFaq105" />
                        </div>
                      </div>
                      <div class="intro-x mt-3">
                        <span>
                          2. ทำการเลือกชื่อ Credential ที่สร้างไว้ก่อนหน้า โดยจะต้องผูกกับเว็บไซต์ธนาคารให้ถูกต้องและตรงกันกับตอนสร้าง
                        </span>
                        <div class="thumbnail"  >
                           <img alt="random image" loading="lazy" :src="imgFaq106" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-11" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-11"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-11">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          11. หากมีการเปลี่ยนแปลงรหัสผ่านจะต้องแจ้งเจ้าหน้าที่หรือสามารถเปลี่ยนแปลงเองได้อย่างไร
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-11"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-11"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                        ลูกค้าสามารถเปลี่ยนแปลงรหัสผ่านเองได้เลย โดยไม่จำเป็นต้องแจ้งเจ้าหน้าที่ โดยทำการเปลี่ยนแปลงดังนี้
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          1. เข้าไปที่หน้าของ Lightwork ไปที่เมนู: Credentials กด icon ตามรูป แล้วเลือก Secret Vault
                        </span>
                        <div class="thumbnail" style="height:350px">
                           <img alt="random image" loading="lazy" :src="imgFaq111" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          2. จะพบหน้าขึ้นมาให้แก้ไขข้อมูล จากนั้นพิมพ์ Confirm เพื่อเป็นการยืนยันความถูกต้องและกดปุ่ม Update
                        </span>
                        <div class="thumbnail" style="height:800px">
                           <img alt="random image" loading="lazy" :src="imgFaq112" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-12" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-12"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-12">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          12. หากถึงรอบการเปลี่ยนแปลงรหัสผ่านของธนาคารจะทราบได้อย่างไร
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-12"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-12"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                        • ตามปกติแล้วธนาคารจะมีระยะเวลาที่กำหนดในการให้ลูกค้าเข้ามาเปลี่ยนแปลงรหัสผ่าน เช่น ทุกๆ 90 วันจะต้องมาเปลี่ยนหรือมี Email แจ้งเตือนจากธนาคารไปยังลูกค้า ฉะนั้นทาง Lightwork แนะนำให้เข้ามาเปลี่ยนรหัสผ่านทุก 60 วัน หรือตามที่ธนาคารกำหนด หากลูกค้าทราบว่าถึงเวลาที่จะต้องเปลี่ยนรหัสผ่านแล้ว ให้ดำเนินการเปลี่ยนแปลงก่อน หากไม่เข้ามาเปลี่ยน เมื่อระบบ StatementPro ทำงานแล้วพบว่ารหัสผ่านผิด จะไม่สามารถเข้าถึงหน้าเว็บไซต์ของธนาคารเพื่อดึงข้อมูลออกมาได้
                      </div>
                      <div class="mt-3 intro-x">
                        • ทั้งนี้ในตอนสร้างข้อมูล Login จะมีให้กรอก Password Date Reminder หากลูกค้าทราบว่า Password ของธนาคารนั้นๆ จะหมดอายุภายในวันใด ให้กรอกไว้ ระบบจะทำการแจ้งเตือนผ่านหน้าเว็บไซต์ StatementPro เพื่อให้ทราบว่าต้องทำการเข้ามาเปลี่ยน Password โดยจะแจ้งเตือนก่อนถึงวันที่บันทึกไว้ 7 วัน
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:470px">
                           <img alt="random image" loading="lazy" :src="imgFaq12" />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-13" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-13"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-13">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          13. กรณีกรอกรหัสผ่านผิด สามารถแก้ไขเองได้อย่างไร
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-13"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-13"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                       <div class="intro-x">
                        <span>ลูกค้าสามารถเปลี่ยนแปลงรหัสผ่านเองได้ ตามหัวข้อที่ 11</span>
                       </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-14" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-14"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-14">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          14. หากต้องการยกเลิกหรือปิด Bank Account ชั่วคราว สามารถทำอย่างไรได้บ้าง
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-14"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-14"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                        สามารถดำเนินการตามขั้นตอนดังนี้
                      </div>
                      <div class="mt-3 intro-x">
                        <span> 1. เข้าไปที่หน้าของ Lightwork ไปที่เมนู: Bank Management จากนั้นจากตัวอย่างจะปิดชั่วคราวเฉพาะ ธนาคารกสิกรไทย</span>
                        <div class="thumbnail">
                           <img alt="random image" loading="lazy" :src="imgFaq141" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span> 2. คลิกที่ปุ่ม จุด 3 จุดด้านหลัง แล้วเลือก Inactive</span>
                        <div class="thumbnail" style="height:460px" >
                           <img alt="random image" loading="lazy" :src="imgFaq142" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span> 3. Account นั้นจะเปลี่ยน Status เป็น Inactive</span>
                        <div class="thumbnail" style="height:460px">
                           <img alt="random image" loading="lazy" :src="imgFaq143" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span> 4. กรณีต้องการเปิดกลับมาให้กดที่ปุ่ม จุด 3 จุดด้านหลัง และเลือกเป็น Active</span>
                        <div class="thumbnail" style="height:460px">
                           <img alt="random image" loading="lazy" :src="imgFaq144" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-15" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-15"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-15">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          15. หากเว็บไซต์ของธนาคารปิด/ไม่สามารถเข้าได้ชั่วคราว ทำให้ไม่สามารถเชื่อมข้อมูลได้ ระบบ StatementPro จะสามารถดึงข้อมูลย้อนหลังเมื่อเว็บไซต์ของธนาคารกลับมาทำงานได้ตามปกติแล้วหรือไม่
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-15"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-15"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                        • ระบบ StatementPro  จะดำเนินการดึงข้อมูล Statement ย้อนหลังกลับมาให้ เฉพาะกรณีที่เว็บของธนาคารปิด/มีการเปลี่ยนแปลง ในรอบถัดไป
                      </div>
                      <div class="intro-x mt-3">
                        • หากเป็นปัญหาที่เกิดจาก Login ของลูกค้าเอง เช่น Username/Password ผิดจะไม่ทำการ ดึงข้อมูล Statement ย้อนหลังกลับมาให้ และลูกค้าจะต้องเข้ามาแก้ไข Password ให้ถูกต้องเอง เพื่อให้วันถัดไปสามารถกลับมา Login เข้าเว็บไซต์เพื่อดึงข้อมูลได้
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-16" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-16"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-16">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          16. ลูกค้าจะสามารถเลือกวันที่ในการ Download ข้อมูลจากเว็บไซต์ของธนาคารได้หรือไม่
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-16"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-16"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                        ไม่สามารถเลือกได้ ปัจจุบันระบบจะตาม Working date ที่ Setting ไว้
                      </div>
                      <div class="mt-3 intro-x">
                        • Intraday - ดึงข้อมูลวันปัจจุบัน
                      </div>
                      <div class="mt-3 intro-x">
                        • Backdate – ดึงข้อมูลย้อนหลัง 1 วัน
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-17" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-17"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-17">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          17. ลูกค้าจะทราบได้อย่างไร หากไม่สามารถ Download ข้อมูลในบาง Bank Account ได้
                        </span>
                        <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-17"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-17"
                    data-bs-parent="#faq-accordion-1">
                    <div class="accordion-body  text-white dark:text-white leading-relaxed p-3">
                      <div class="intro-x">
                        สามารถตรวจสอบได้ที่หน้า Dashboard โดยจะมีสรุปของแต่ละวันว่ามี Account ใดบ้างที่ทำงาน Completed หรือ Account ใดบ้างที่ Error พร้อมทั้ง Error Message ว่าเกิดจากสาเหตุใด
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:500px">
                           <img alt="random image" loading="lazy" :src="imgFaq17"  />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import imgFaq91 from '@/assets/faq/9-1.png'
import imgFaq92 from '@/assets/faq/9-2.png'
import imgFaq93 from '@/assets/faq/9-3.png'
import imgFaq101 from '@/assets/faq/10-1.png'
import imgFaq102 from '@/assets/faq/10-2.png'
import imgFaq103 from '@/assets/faq/10-3.png'
import imgFaq104 from '@/assets/faq/10-4.png'
import imgFaq105 from '@/assets/faq/10-5.png'
import imgFaq106 from '@/assets/faq/10-6.png'
import imgFaq111 from '@/assets/faq/11-1.png'
import imgFaq112 from '@/assets/faq/11-2.png'
import imgFaq12 from '@/assets/faq/12.png'
import imgFaq141 from '@/assets/faq/14-1.png'
import imgFaq142 from '@/assets/faq/14-2.png'
import imgFaq143 from '@/assets/faq/14-3.png'
import imgFaq144 from '@/assets/faq/14-4.png'
import imgFaq17 from '@/assets/faq/17.png'
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      imgFaq91,
      imgFaq92,
      imgFaq93,
      imgFaq101,
      imgFaq102,
      imgFaq103,
      imgFaq104,
      imgFaq105,
      imgFaq106,
      imgFaq111,
      imgFaq112,
      imgFaq12,
      imgFaq141,
      imgFaq142,
      imgFaq143,
      imgFaq144,
      imgFaq17,
      listBank: [],
      listLightworkData: []
    }
  },
  methods: {
    initializeBank() {
      this.listBank.push({
        bank: 'ธนาคารกรุงศรีอยุธยา (BAY)',
        site: 'Krungsri BizOnline'
      })
      this.listBank.push({
        bank: 'ธนาคารกรุงศรีอยุธยา (BAY)',
        site: 'Krungsri CashLink'
      })
      this.listBank.push({
        bank: 'ธนาคารกรุงเทพ (BBL)',
        site: 'Biz iBanking'
      })
      this.listBank.push({
        bank: 'ธนาคารกรุงเทพ (BBL)',
        site: 'Corporate iCash'
      })
      this.listBank.push({
        bank: 'ธนาคารกรุงเทพ (BBL)',
        site: 'Enterprise Banking Services'
      })
      this.listBank.push({
        bank: 'ธนาคารกสิกรไทย (KBANK)',
        site: 'K-BIZ'
      })
      this.listBank.push({
        bank: 'ธนาคารกสิกรไทย (KBANK)',
        site: 'K-Cash Connect Plus'
      })
      this.listBank.push({
        bank: 'ธนาคารกรุงไทย (KTB)',
        site: 'Krungthai Corporate Online (เว็บไซต์รูปแบบเดิม)'
      })
      this.listBank.push({
        bank: 'ธนาคารกรุงไทย (KTB)',
        site: 'Krungthai Corporate Online (เว็บไซต์รูปแบบใหม่)'
      })
      this.listBank.push({
        bank: 'ธนาคารไทยพาณิชย์ (SCB)',
        site: 'Business Net'
      })
      this.listBank.push({
        bank: 'ธนาคารทหารไทยธนชาต (TTB)',
        site: 'Business One',
        isActive: false
      })
      this.listBank.push({
        bank: 'ธนาคารทหารไทยธนชาต (TTB)',
        site: 'Business Click'
      })
    },
    initializeLightworkData() {
      this.listLightworkData.push({
        id: 1,
        text: 'ประเภทข้อมูลที่ต้องการ (Bill Payment/Statement)'
      })
      this.listLightworkData.push({
        id: 2,
        text: 'ชื่อธนาคาร/ชื่อเว็บไซต์ธนาคาร'
      })
      this.listLightworkData.push({
        id: 3,
        text: 'เลขบัญชีธนาคาร'
      })
      this.listLightworkData.push({
        id: 4,
        text: 'Working Date (ประเภทรายงานข้อมูลที่ต้องการ Intraday/Backdate)'
      })
      this.listLightworkData.push({
        id: 5,
        text: 'Company Name (ถ้ามีเพื่อใช้เลือกในหน้าเว็บไซต์ธนาคาร)'
      })
      this.listLightworkData.push({
        id: 6,
        text: 'Username (สำหรับใช้ Login เข้าเว็บไซต์ของธนาคาร)'
      })
      this.listLightworkData.push({
        id: 7,
        text: 'Password (สำหรับใช้ Login เข้าเว็บไซต์ของธนาคาร)'
      })
      this.listLightworkData.push({
        id: 8,
        text: 'Company ID (ถ้ามี สำหรับใช้ Login เข้าเว็บไซต์ของธนาคาร)'
      })
    }
  },
  created() {
    this.initializeBank()
    this.initializeLightworkData()
  }
})
</script>
