<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="hidden mr-auto -intro-x breadcrumb sm:flex">
      <span class="text-color-highlight">Application</span>
      <ChevronRightIcon class="breadcrumb__icon text-color-highlight" />
      <span class="breadcrumb--active">{{ currentPageName }}</span>
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Notifications -->
    <div class="hidden mr-auto intro-x dropdown sm:mr-6">
      <div class="cursor-pointer dropdown-toggle notification notification--bullet" role="button" aria-expanded="false">
        <BellIcon class="notification__icon dark:text-gray-300" />
      </div>
      <div class="pt-2 notification-content dropdown-menu">
        <div class="notification-content__box dropdown-menu__content box dark:bg-dark-6">
          <div class="notification-content__title">Notifications</div>
          <div v-for="(faker, fakerKey) in $_.take($f(), 5)" :key="fakerKey"
            class="relative flex items-center cursor-pointer" :class="{ 'mt-5': fakerKey }">
            <div class="flex-none w-12 h-12 mr-1 image-fit">
              <img alt="Icewall Tailwind HTML Admin Template" class="rounded-full"
                :src="require(`@/assets/images/${faker.photos[0]}`)" />
              <div class="absolute bottom-0 right-0 w-3 h-3 border-2 border-white rounded-full bg-theme-9"></div>
            </div>
            <div class="ml-2 overflow-hidden">
              <div class="flex items-center">
                <a href="javascript:;" class="mr-5 font-medium truncate">{{
                  faker.users[0].name
                }}</a>
                <div class="ml-auto text-xs text-gray-500 whitespace-nowrap">
                  {{ faker.times[0] }}
                </div>
              </div>
              <div class="w-full truncate text-gray-600 mt-0.5">
                {{ faker.news[0].shortContent }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Notifications -->
    <!-- BEGIN: Currency -->
    <div class="pr-4 mr-4 intro-x dropdown sm:mr-6" id="currencyToggle" style="border-right: 1px solid !important;">
      <div class="cursor-pointer dropdown-toggle" role="button" aria-expanded="false">
        <a href="https://www.youtube.com/watch?v=gl5siVp35AU" target="_blank" style="cursor:pointer"
          class="flex items-center p-2 text-blue-400 transition duration-300 ease-in-out rounded-md hover:bg-theme-1 dark:hover:bg-dark-3">
          <img class="w-6 h-6 mr-3" :src="imgYoutube" />
          <label style="cursor:pointer;text-decoration: underline;"> วิธีการใช้งาน StatementPro</label>
        </a>
        <!-- <div class="flex flex-row justify-between">
          <h1 class="self-center ml-2 text-lg">
            Account Quota: {{ currentQuotaData.totalUsedQuota }} /{{ currentQuotaData.totalQuota }} | Exp. <nobr
              :class="{ 'text-red-300': Date.parse(new Date()) > Date.parse(currentQuotaData.quotaExpireDate) }">{{
                $filters.formatDate(currentQuotaData.quotaExpireDate) }}</nobr>
          </h1>
        </div> -->
      </div>
    </div>
    <!-- END: Currency -->
    <!-- BEGIN: Account Menu -->
    <div class="w-8 h-8 intro-x dropdown" id="accountToggle">
      <div class="w-8 h-8 overflow-hidden rounded-full shadow-lg dropdown-toggle image-fit zoom-in" role="button"
        aria-expanded="false">
        <img alt="Icewall Tailwind HTML Admin Template" :src="require(`@/assets/images/${$f()[9].photos[0]}`)" />
      </div>
      <div class="w-56 dropdown-menu">
        <div class="text-white dropdown-menu__content box bg-color-ddl">
          <div class="p-4 border-color">
            <div class="font-medium">{{ userProfile?.firstName + ' ' + userProfile?.lastName }}</div>
          </div>
          <div v-if="isSyncProvider === true" class="p-2 border-color">
            <a href="#" @click.prevent="handleBackToPeak"
              class="flex items-center block p-2 transition duration-300 ease-in-out rounded-md hover:bg-theme-1 dark:hover:bg-dark-3">
              <ChevronLeftIcon class="w-4 h-4 mr-2" /> Back To PEAK
            </a>
          </div>
          <div class="p-2">
            <a href="#" @click.prevent="onProfileClicked"
              class="flex items-center block p-2 transition duration-300 ease-in-out rounded-md hover:bg-theme-1 dark:hover:bg-dark-3">
              <UserIcon class="w-4 h-4 mr-2" /> Manage Profile
              <Tippy  v-if="userProfile?.isEmailVerified === false"  class="ml-2 -mt-1 text-yellow-400 tooltip"
                content="Email has not been verified."><AlertCircleIcon class="w-4 h-4" /></Tippy>
            </a>
          </div>
          <div class="p-2 border-t border-color">
            <a href="#" @click.prevent="handleLogout"
              class="flex items-center block p-2 transition duration-300 ease-in-out rounded-md hover:bg-theme-1 dark:hover:bg-dark-3">
              <ToggleRightIcon class="w-4 h-4 mr-2" /> Logout
            </a>
          </div>
          <div class="p-4 border-t border-color" style="display: none;">
            <div class="font-medium">
              <DarkModeSwitcher :isAttach="true" />
            </div>
            <div class="text-xs text-theme-28 -mt-7 dark:text-gray-600">
              Theme mode
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { mapGetters } from 'vuex'
import { authService } from '@/services'
import imgYoutube from '@/assets/images/Youtube32.png'

export default defineComponent({
  computed: {
    ...mapGetters({
      storeCurrency: 'main/currentCurrency',
      currentPageName: 'main/currentPageName',
      currentQuotaData: 'main/currentQuotaData',
      isSyncProvider: 'main/isSyncProvider',
      userProfile: 'main/userProfile'
    })
  },
  components: { DarkModeSwitcher },
  data() {
    return {
      imgYoutube,
      priceList: [],
      currentCurrency: false
    }
  },
  setup() {
    const router = useRouter()
    const searchDropdown = ref(false)

    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    const handleLogout = () => {
      document.getElementById('accountToggle').click()
      window.localStorage.clear()
      setTimeout(function () { window.location.href = `${window.__env.api.frontEndPreUrl}login` }, 1000)
    }

    const handleProfile = () => {
      document.getElementById('accountToggle').click()
      router.push({
        name: 'profile'
      })
    }

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      handleProfile,
      handleLogout,
      onProfileClicked: handleProfile
    }
  },
  async mounted() {
    const profile = { ...this.userProfile }
    const tempProfile = await authService.GetUserProfile(profile.id)
    Object.keys(tempProfile).forEach(_ => {
      profile[_] = tempProfile[_]
    })
    this.$store.dispatch('main/setUserProfile', profile)
  },
  methods: {
    handleChangeCurrency(item) {
      this.currentCurrency = item
      this.$store.dispatch('main/setCurrentCurrency', JSON.stringify(this.currentCurrency))
      document.getElementById('currencyToggle').click()
    },
    getPageName() {
      return localStorage.getItem('pageName')
    },
    async handleBackToPeak() {
      const res = await authService.getProviderConnectFromUser()
      if (res && res.tokenJSON) {
        const peakObj = JSON.parse(res.tokenJSON)
        const redirectURL = `https://peakappconnectservice-production.azurewebsites.net/api/Lightwork/LightworkDetail?PeakToken=${peakObj.PeakToken}&PeakUserID=${peakObj.PeakUserId}&LWToken=${this.userProfile?.refKey}`
        window.location = redirectURL
      }
    }
  }
})
</script>
