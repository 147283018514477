<template>
  <div class="mt-5 intro-y box">
    <div class="items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <div class="mr-auto text-base font-medium">
        <div class="flex">
          <span>Change Password</span>
        </div>
      </div>
    </div>
    <div class="p-5">
      <div class="grid grid-cols-12 gap-x-5">
        <div class="col-span-12 xl:col-span-6">
          <div>
            <label id="profile-form-2" class="form-label">Current Password</label>
            <label class="ml-2 text-theme-6">*</label>
            <div class="input-group">
              <div :class="{ disabled: isUpdating }" class="input-group-text ">
                <LockIcon class="block w-3 mx-auto" />
              </div>
              <input id="profile-form-2" :type="isShowCurrentPassword ? 'text' : 'password'" autocomplete="off"
                class="border-gray-300 form-control" :disabled="isUpdating" placeholder="Password"
                v-model.trim="v$.currentPassword.$model" aria-label="Password" aria-describedby="input-group-password" />
              <div div id="input-group-password " class="cursor-pointer input-group-text"
                :class="isUpdating ? 'disabled' : ''" :disabled="isUpdating"
                @click="isShowCurrentPassword = !isShowCurrentPassword">
                <EyeOffIcon v-if="isShowCurrentPassword" class="block w-3 mx-auto " />
                <EyeIcon v-else class="block w-3 mx-auto " />
              </div>
            </div>
            <template v-if="v$?.currentPassword?.$errors">
              <div class="grid ">
                <label v-for="(error, index) in v$?.currentPassword?.$errors" :key="index"
                  class="mt-2 form-label text-theme-6">
                  {{ error?.$message }}
                </label>
              </div>
            </template>
          </div>
          <div class="mt-3">
            <label id="profile-form-3" class="form-label">New Password</label>
            <label class="ml-2 text-theme-6">*</label>
            <div class="dropdown" id="policy-password-dropdown" data-placement="bottom-end">
              <div class="input-group">
                <div :class="{ disabled: isUpdating }" class="input-group-text ">
                  <LockIcon class="block w-3 mx-auto" />
                </div>
                <input id="profile-form-3" :type="isShowNewPassword ? 'text' : 'password'" autocomplete="off"
                  @focusout="onNewPasswordFocusOut" @focusin="onNewPasswordFocusIn"
                  class="border-gray-300 form-control dropdown-toggle" :disabled="isUpdating" placeholder="Password"
                  v-model.trim="v$.newPassword.$model" aria-label="Password" aria-describedby="input-group-password" />
                <div div id="input-group-password " class="cursor-pointer input-group-text"
                  :class="isUpdating ? 'disabled' : ''" :disabled="isUpdating"
                  @click="isShowNewPassword = !isShowNewPassword">
                  <EyeOffIcon v-if="isShowNewPassword" class="block w-3 mx-auto " />
                  <EyeIcon v-else class="block w-3 mx-auto " />
                </div>
              </div>
              <div class="dropdown-menu">
                <div v-if="!$helpers.isNull(policyPassword.rule)"
                  style="display: flex;align-items: center;place-content: center; ">
                  <div class="mt-5 mb-5 container-password">
                    <div>
                      {{ safePasswordMessage }}
                    </div>
                    <div class="mt-2 mb-2 progress-bar_wrap ">
                      <div class=" progress-bar_item" :class="updateProgressBar(1)"></div>
                      <div class="progress-bar_item" :class="updateProgressBar(2)"></div>
                      <div class="progress-bar_item" :class="updateProgressBar(3)"></div>
                    </div>
                    <div>
                      รหัสผ่านจะต้องประกอบไปด้วย:
                    </div>
                    <ul class="mt-2 -ml-2 ui-password">
                      <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainMinCharacters }"
                        v-if="policyPassword.rule.minLength > 0">
                        ต้องมีความยาวรหัสผ่านอย่างน้อย <strong>{{ policyPassword.rule.minLength }}</strong> ตัวขึ้นไป
                      </li>
                      <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainMaxCharacters }"
                        v-if="policyPassword.rule.maxLength > 0">
                        ต้องมีความยาวรหัสผ่านไม่เกิน <strong>{{ policyPassword.rule.maxLength }}</strong> ตัว
                      </li>
                      <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainNumber }"
                        v-if="policyPassword.rule.minNumbers > 0">
                        ต้องมีตัวเลข (0-9) <strong>{{ policyPassword.rule.minNumbers }}</strong> ตัวขึ้นไป
                      </li>
                      <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainUppercase }"
                        v-if="policyPassword.rule.minUpperCase > 0">
                        ต้องมีตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ (A-Z) <strong>{{ policyPassword.rule.minUpperCase }}</strong>
                        ตัวขึ้นไป
                      </li>
                      <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainLowercase }"
                        v-if="policyPassword.rule.minLowerCase > 0">
                        ต้องมีตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) <strong>{{ policyPassword.rule.minLowerCase }}</strong>
                        ตัวขึ้นไป
                      </li>
                      <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainSpecialCharacter }"
                        v-if="policyPassword.rule.minIncludeSpecialChar > 0">
                        ต้องมีอักขระพิเศษ (!@#$%^&+=) <strong>{{ policyPassword.rule.minIncludeSpecialChar }}</strong>
                        ตัวขึ้นไป
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="v$?.newPassword?.$errors">
              <div class="grid ">
                <label v-for="(error, index) in v$?.newPassword?.$errors" :key="index"
                  class="mt-2 form-label text-theme-6">
                  {{ error?.$message }}
                </label>
              </div>
            </template>
            <template v-if="!$helpers.isNull(errorPasswordMessage)">
              <div class="grid ">
                <label class="mt-2 form-label text-theme-6">
                  {{ errorPasswordMessage }}
                </label>
              </div>
            </template>
          </div>
          <div class="mt-3">
            <label id="profile-form-4" :class="$helpers.isNull(newPassword) ? 'text-gray-600' : ''"
              class="form-label">Confirm Password</label>
            <label class="ml-2 text-theme-6" v-if="!$helpers.isNull(newPassword)">*</label>
            <div class="input-group">
              <div :class="isUpdating || $helpers.isNull(newPassword) ? 'disabled' : ''" class="input-group-text ">
                <LockIcon class="block w-3 mx-auto" />
              </div>
              <input id="profile-form-4" :type="isShowConfirmPassword ? 'text' : 'password'" autocomplete="off"
                class="border-gray-300 form-control" :disabled="isUpdating || $helpers.isNull(newPassword)"
                placeholder="Password" v-model.trim="v$.confirmPassword.$model" aria-label="Password"
                aria-describedby="input-group-password" />
              <div div id="input-group-password " class="cursor-pointer input-group-text"
                :class="isUpdating || $helpers.isNull(newPassword) ? 'disabled' : ''" :disabled="isUpdating"
                @click="isShowConfirmPassword = !isShowConfirmPassword">
                <EyeOffIcon v-if="isShowConfirmPassword" class="block w-3 mx-auto " />
                <EyeIcon v-else class="block w-3 mx-auto " />
              </div>
            </div>
            <template v-if="$helpers.isNull(newPassword) ? false : v$?.confirmPassword?.$errors">
              <div class="grid ">
                <label v-for="(error, index) in v$?.confirmPassword?.$errors" :key="index"
                  class="mt-2 form-label text-theme-6">
                  {{ error?.$message }}
                </label>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="justify-end p-5 mt-3 border-t border-gray-200 dark:border-dark-5">
      <div class="flex col-span-12 mb-4 text-theme-6" v-if="!$helpers.isNull(errorMessage)">
        <img :src="imgError" class="w-6 h-6 mr-2 " />
        {{ errorMessage }}
      </div>
      <div>
        <button type="button" class="mr-auto btn btn-primary" @click="onUpdateClicked" :disabled="isUpdating || v$?.currentPassword?.$errors?.length > 0 || v$?.newPassword?.$errors?.length > 0
          || v$?.confirmPassword?.$errors?.length > 0 || !isValidPolicyPassword">
          <LoadingIcon v-if="isUpdating" icon="oval" class="w-6 h-6 mr-2 text-center" />
          {{ isUpdating ? 'Updating' : 'Update' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { authService } from '@/services'
import imgError from '@/assets/images/Error32.png'
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs, helpers } from '@vuelidate/validators'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update'],
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true
    })
  }),
  data: () => ({
    isShowCurrentPassword: false,
    isShowNewPassword: false,
    isShowConfirmPassword: false,
    isUpdating: false,
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
    errorMessage: null,
    errorPasswordMessage: null,
    safePasswordMessage: null,
    imgError,
    policyPassword: {
      rule: null,
      validations: {
        isContainMinCharacters: false,
        isContainMaxCharacters: false,
        isContainNumber: false,
        isContainUppercase: false,
        isContainLowercase: false,
        isContainSpecialCharacter: false
      }
    }
  }),
  validations() {
    return {
      currentPassword: {
        required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required)
      },
      newPassword: {
        required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required),
        notSameAsPassword: helpers.withMessage('รหัสผ่านใหม่ซ้ำกับปัจจุบัน',
          (value) => {
            if (this.$helpers.isNull(value) || this.$helpers.isNull(this.currentPassword)) {
              return true
            }
            return this.currentPassword !== value
          })
      },
      confirmPassword: {
        required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required),
        sameAsPassword: helpers.withMessage('ยืนยันรหัสผ่านไม่ตรงกัน', sameAs(this.newPassword))
      }
    }
  },
  watch: {
    newPassword(newVal, oldVal) {
      this.validationRulePolicyPassword()
    }
  },
  computed: {
    isValidPolicyPassword() {
      return this.policyPassword.validations.isContainMinCharacters &&
        this.policyPassword.validations.isContainMaxCharacters &&
        this.policyPassword.validations.isContainNumber &&
        this.policyPassword.validations.isContainUppercase &&
        this.policyPassword.validations.isContainLowercase &&
        this.policyPassword.validations.isContainSpecialCharacter
    }
  },
  methods: {
    updateProgressBar(val) {
      const length = this.newPassword?.length ?? 0
      if (length === 0) {
        this.safePasswordMessage = 'ความปลอดภัยรหัสผ่าน'
        return null
      } else if (length > 0 && length <= 4) {
        this.safePasswordMessage = 'รหัสผ่านไม่ปลอดภัย'
        if (val === 1) {
          return 'active bg-red-500'
        }
      } else if (length > 4 && length <= 8) {
        this.safePasswordMessage = 'รหัสผ่านดี'
        if (val === 1 || val === 2) {
          return 'active bg-yellow-500'
        }
      } else {
        this.safePasswordMessage = 'รหัสผ่านดีมาก'
        return 'active bg-green-500'
      }
    },
    validationRulePolicyPassword() {
      this.errorPasswordMessage = null
      if (this.$helpers.isNull(this.newPassword)) {
        this.confirmPassword = this.newPassword
        this.policyPassword.validations.isContainMinCharacters = false
        this.policyPassword.validations.isContainMaxCharacters = false
        this.policyPassword.validations.isContainNumber = false
        this.policyPassword.validations.isContainUppercase = false
        this.policyPassword.validations.isContainLowercase = false
        this.policyPassword.validations.isContainSpecialCharacter = false
        return
      }
      // Check Contain Min Characters
      if (this.policyPassword.rule.minLength > 0) {
        if (this.newPassword.length >= this.policyPassword.rule.minLength) {
          this.policyPassword.validations.isContainMinCharacters = true
        } else {
          this.errorPasswordMessage = `ต้องมีความยาวอย่างน้อย ${this.policyPassword.rule.minLength} ตัวขึ้นไป`
          this.policyPassword.validations.isContainMinCharacters = false
        }
      } else {
        this.policyPassword.validations.isContainMinCharacters = true
      }
      // Check Contain Max Characters
      if (this.policyPassword.rule.maxLength > 0) {
        if (this.newPassword.length <= this.policyPassword.rule.maxLength) {
          this.policyPassword.validations.isContainMaxCharacters = true
        } else {
          this.errorPasswordMessage = `ต้องมีความยาวไม่เกิน ${this.policyPassword.rule.maxLength} ตัว`
          this.policyPassword.validations.isContainMaxCharacters = false
        }
      } else {
        this.policyPassword.validations.isContainMaxCharacters = true
      }
      // Check Contain Number
      if (this.policyPassword.rule.minNumbers > 0) {
        const findNumberMatch = this.newPassword.match(/\d+/g)?.join('') ?? ''
        if (findNumberMatch.length >= this.policyPassword.rule.minNumbers) {
          this.policyPassword.validations.isContainNumber = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีตัวเลข (0-9) ${this.policyPassword.rule.minNumbers} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainNumber = false
        }
      } else {
        this.policyPassword.validations.isContainNumber = true
      }
      // Check Contain Uppercase
      if (this.policyPassword.rule.minUpperCase > 0) {
        const findNumberMatch = this.newPassword.match(/[A-Z]/g)?.join('') ?? ''
        if (findNumberMatch.length >= this.policyPassword.rule.minUpperCase) {
          this.policyPassword.validations.isContainUppercase = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีตัวอักษรภาษาอังกฤษพิมพ์เล็ก (A-Z) ${this.policyPassword.rule.minUpperCase} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainUppercase = false
        }
      } else {
        this.policyPassword.validations.isContainUppercase = true
      }
      // Check Contain Lowercase
      if (this.policyPassword.rule.minLowerCase > 0) {
        const findNumberMatch = this.newPassword.match(/[a-z]/g)?.join('') ?? ''
        if (findNumberMatch.length >= this.policyPassword.rule.minLowerCase) {
          this.policyPassword.validations.isContainLowercase = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) ${this.policyPassword.rule.minLowerCase} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainLowercase = false
        }
      } else {
        this.policyPassword.validations.isContainLowercase = true
      }
      // Check Contain Special Character
      if (this.policyPassword.rule.minIncludeSpecialChar > 0) {
        const findSpecialMatch = this.newPassword.match(/[ !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/g)?.join('') ?? ''
        if (findSpecialMatch.length >= this.policyPassword.rule.minIncludeSpecialChar) {
          this.policyPassword.validations.isContainSpecialCharacter = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีอักขระพิเศษ (!@#$%^&+=) ${this.policyPassword.rule.minIncludeSpecialChar} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainSpecialCharacter = false
        }
      } else {
        this.policyPassword.validations.isContainSpecialCharacter = true
      }
    },
    onNewPasswordFocusIn() {
      cash('#policy-password-dropdown').dropdown('show')
    },
    onNewPasswordFocusOut() {
      cash('#policy-password-dropdown').dropdown('hide')
    },
    async onUpdateClicked() {
      this.errorMessage = null
      this.v$.$touch()
      if (this.v$.$invalid) {
        this.$helpers.showToastify('Validate-Failed', 'Please check your password', true)
      } else {
        this.isUpdating = true
        try {
          const payload = {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
            confirmPassword: this.confirmPassword
          }
          const res = await authService.updatePassword(this.modelValue.id, payload)
          if (this.$helpers.isNull(res?.statusCode)) {
            this.$helpers.showToastify('Password updated successfully.')
            this.$emit('update')
          } else {
            this.errorMessage = res.message
          }
        } catch (error) {
          this.errorMessage = error.message
        } finally {
          this.isUpdating = false
        }
      }
    }
  },
  async mounted() {
    this.policyPassword.rule = await authService.getPolicyPassword()
    this.v$.$touch()
  }
})
</script>
