import ScholarService from './ScholarService'
import SecurityService from './SecurityService'
import LogService from './LogService'
import BankService from './BankService'
import AuthService from './AuthService'
import CloudService from './CloudService'
const securityService = new SecurityService()
const scholarService = new ScholarService()
const logService = new LogService()
const bankService = new BankService()
const authService = new AuthService()
const cloudService = new CloudService()
export {
  scholarService,
  securityService,
  logService,
  bankService,
  authService,
  cloudService
}
