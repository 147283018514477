<template>
  <div>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box p-5 mt-5">
      <div class="grid xl:grid-cols-5 lg:grid-cols-2 grid-cols-1">
        <div class="lg:border-r border-gray-200 dark:border-dark-5">
          <div class="flex text-center h-32 rounded-xl relative px-5 items-center justify-center lg:justify-start">
            <div class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg ">
              {{ userProfile?.firstName + ' ' + userProfile?.lastName }}
            </div>
          </div>
        </div>
        <div class="grid col-span-4 grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-4 px-4">
          <div class="flex flex-col text-center justify-center box-d h-32 rounded-xl relative">
            <div v-if="isLoading == false" class="text-4xl font-bold text-color-highlight mb-2">
              {{ totalSuccess }}
            </div>
            <div v-else class="text-4xl font-bold text-color-highlight text-xl mb-2">
              <Skeletor width="30%" />
            </div>
            <div class="text-gray-600 dark:text-white text-base text-center absolute w-full bottom-1.5">
              Complete
            </div>
          </div>
          <div class="flex flex-col text-center justify-center box-d h-32 rounded-xl relative">
            <div v-if="isLoading == false" class="text-4xl font-bold text-color-error mb-2">
              {{ totalFail }}
            </div>
            <div v-else class="text-xl font-bold text-color-highlight text-xl mb-2">
              <Skeletor width="30%" />
            </div>
            <div class="text-gray-600 dark:text-white text-base text-center absolute w-full bottom-1.5">
              Error
            </div>
          </div>
          <div class="flex flex-col text-center justify-center box-d h-32 rounded-xl relative">
            <div v-if="isLoading == false" class="text-4xl font-bold mb-2">
              {{ totalAccount }}
            </div>
            <div v-else class="text-xl font-bold text-color-highlight text-xl mb-2">
              <Skeletor width="30%" />
            </div>
            <div class="text-gray-600 dark:text-white text-base text-center absolute w-full bottom-1.5">
              Total
            </div>
          </div>
          <div class="flex flex-col text-center justify-center h-32 rounded-xl relative">
            <div class="text-2xl font-medium mb-2">
              <!-- 22 Feb 2023 (15:57) -->
              {{ filterMaxDate ? filterMaxDate : '-' }}
            </div>
            <div class="text-gray-600 dark:text-white text-base text-center absolute w-full bottom-1.5">
              Latest Updated
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row justify-between">
      <div role="tablist" class="nav nav-tabs">
        <a id="error-tab" data-toggle="tab" data-target="#error" href="javascript:;"
          class="py-4 sm:mr-8 active text-color-error " role="tab" aria-controls="dashboard"
          aria-selected="true">
          Errors
          <span v-if="listErrorStatement.length > 0">
            ({{ listErrorStatement.length }})
          </span>
        </a>
        <a id="complete-tab" data-toggle="tab" data-target="#complete" href="javascript:;"
          class="py-4 sm:mr-8 text-color-highlight" role="tab" aria-selected="false">
          Complete
          <span v-if="listCompleteStatement.length > 0">
            ({{ listCompleteStatement.length }})
          </span>
        </a>
      </div>
      <!-- <div>2</div> -->
      <div class="flex py-3 sm:mr-8">
        <litepie-datepicker readonly :formatter="dateFormatter" :auto-apply="false"
          use-range placeholder="Date: From - To"
          class="py-4 sm:mr-8 mt-2 w-60" v-model="dateStartToEnd"/>
        <div class="flex-shrink-0 mt-2 ml-3">
          <button id="open-datepicker" class="btn btn-primary self-center" @click="dateStartEnd">
            Start
          </button>
        </div>
      </div>
    </div>
    <!-- END: Profile Info -->
    <div class="tab-content mt-5">
      <div id="error" class="tab-pane active" role="tabpanel" aria-labelledby="error-tab">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <table class="table table-report -mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">Bank</th>
                  <th class="whitespace-nowrap">Type</th>
                  <th class="whitespace-nowrap">Service</th>
                  <th class="whitespace-nowrap">Account Number</th>
                  <th class="whitespace-nowrap">Working Date</th>
                  <th class="whitespace-nowrap">Error Message</th>
                  <th class="text-center whitespace-nowrap">DateTime</th>
                </tr>
              </thead>
              <tbody v-if="!isLoading">
                <tr v-for="item in listErrorStatement" :key="item" class="intro-x">
                  <td>
                    <div class="flex">
                      <i :class="item.iconClass" class="w-4 mr-2"/>
                      {{ item.bankGroupShortName }} - {{ item.bankShortName }}
                    </div>
                  </td>
                  <td>
                    {{ item.statementType }}
                  </td>
                  <td class="">{{ item.bankServiceTypeName }}</td>
                  <td class="">{{ item.bankAccountNo }}</td>
                  <td class="">{{ item.workingDateDay == 0 ? 'Intraday' : 'Backdate' ?? '-' }}</td>
                  <td class="">{{ item.remark }} {{ item.transferRemark }}</td>
                  <td class="text-center">
                    {{ $filters.formatNonUtcDateTime(item.updatedAt) }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="item in 3" :key="item" class="intro-x">
                  <td>
                    <a href="" class="font-medium whitespace-nowrap">
                      <Skeletor />
                    </a>
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      <Skeletor />
                    </div>
                  </td>
                  <td class="">
                    <Skeletor />
                  </td>
                  <td class="text-center">
                    <Skeletor />
                  </td>
                  <td class="w-40">
                    <div class="flex items-center justify-center text-theme-9">
                      <Skeletor />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div id="complete" class="tab-pane" role="tabpanel" aria-labelledby="complete-tab">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <table class="table table-report -mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">Bank</th>
                  <th class="whitespace-nowrap">Type</th>
                  <th class="whitespace-nowrap">Service</th>
                  <th class="whitespace-nowrap">Account Number</th>
                  <th class="whitespace-nowrap">Working Date</th>
                  <th class="whitespace-nowrap">Remark</th>
                  <th class="text-center whitespace-nowrap">DateTime</th>
                </tr>
              </thead>
              <tbody v-if="!isLoading">
                <tr v-for="item in listCompleteStatement" :key="item" class="intro-x">
                  <td>
                    <div class="flex">
                      <i :class="item.iconClass" class="w-4 mr-2"/>
                      {{ item.bankGroupShortName }} - {{ item.bankShortName }}
                    </div>
                  </td>
                  <td>
                    {{ item.statementType }}
                  </td>
                  <td class="">{{ item.bankServiceTypeName }}</td>
                  <td class="">{{ item.bankAccountNo }}</td>
                  <td class="">{{ item.workingDateDay == 0 ? 'Intraday' : 'Backdate' ?? '-' }}</td>
                  <td class="">{{ item.remark }} {{ item.transferRemark }}</td>
                  <td class="text-center">
                    {{ $filters.formatNonUtcDateTime(item.updatedAt) }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="item in 3" :key="item" class="intro-x">
                  <td>
                    <a href="" class="font-medium whitespace-nowrap">
                      <Skeletor />
                    </a>
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      <Skeletor />
                    </div>
                  </td>
                  <td class="">
                    <Skeletor />
                  </td>
                  <td class="text-center">
                    <Skeletor />
                  </td>
                  <td class="w-40">
                    <div class="flex items-center justify-center text-theme-9">
                      <Skeletor />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { bankService } from '@/services'
import profileImg from '@/assets/images/Logo Dashboard.png'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default defineComponent({
  computed: {
    ...mapGetters({
      currentCurrency: 'main/currentCurrency',
      userProfile: 'main/userProfile'
    })
  },
  setup() {
    const dateValue = ref([])
    const dateFormatter = ref({
      date: 'YYYY-MM-DD',
      month: 'MMM'
    })
    return {
      dateValue,
      dateFormatter
    }
  },
  data() {
    return {
      dateStartToEnd: [],
      totalSchloar: 0,
      totalSLP: 0,
      totalCurrency: 0,
      totalCompleteDailyAdventure: 0,
      totalCompleteDailySLP: 0,
      listScholar: [],
      listNearestShcolar: [],
      slpPerCurrency: 0.0,
      profileImg,
      isLoading: true,
      totalSlpManager: 0,
      totalSlpScholar: 0,
      totalSuccess: 0,
      totalAccount: 0,
      totalFail: 0,
      listErrorStatement: [],
      listCompleteStatement: [],
      updatedDate: [],
      filterMaxDate: '',
      errorFilterCondition: item => {
        if (
          item.download === 1 &&
          item.transferStatus === '1' &&
          item.transformStatus === 1
        ) {
          return false
        } else if (
          item.download === 1 &&
          (item.transformStatus === 1 || item.transformStatus === null)
        ) {
          return false
        } else if (
          item.download === 1 &&
          item.transformStatus === 1 &&
          (item.transferStatus !== '0' || item.transferStatus === null)
        ) {
          return false
        } else {
          return true
        }
      },
      completeFilterCondition: item => {
        if (
          item.download === 1 &&
          item.transferStatus === '1' &&
          (item.transformStatus === 1 || item.transformStatus === null)
        ) {
          return true
        } else if (item.download === 1 && item.downloadNoDataStatus === 1) {
          return true
        } else if (
          item.download === 1 &&
          item.transformStatus === 1 &&
          item.transformNoDataStatus === 1
        ) {
          return true
        } else {
          return false
        }
      }
    }
  },
  async mounted() {
    await this.loadSummary()
    this.isLoading = false
  },
  watch: {
    updatedDate: {
      deep: true,
      handler(val, oldVal) {
        if (this.updatedDate.length > 0) {
          const findDate = this.updatedDate.reduce((a, b) =>
            b.updatedAt > a.updatedAt ? b : a
          ).updatedAt
          this.filterMaxDate = (dayjs(findDate).format('DD MMM YYYY H:mm'))
        }
      }
    }
  },
  methods: {
    async loadSummary() {
      const summaryData = await bankService.GetSummary()
      if (summaryData.length > 0) {
        this.totalSuccess = +summaryData[0].success
        this.totalFail = +summaryData[0].fail
        this.totalAccount = +summaryData[0].totalaccount
      }
      const lastTwoDaysData = await bankService.GetLastTwoDays()
      this.listCompleteStatement = lastTwoDaysData.filter(
        this.completeFilterCondition
      )
      this.listErrorStatement = lastTwoDaysData.filter(
        x => !this.completeFilterCondition(x)
      )
      // this.updatedDate = lastTwoDaysData
      if (lastTwoDaysData.length > 0) {
        this.updatedDate = lastTwoDaysData.filter(
          (value, key) => value.updatedAt !== null
        )
      }
      console.log(lastTwoDaysData)
    },
    async dateStartEnd() {
      this.isLoading = true
      if (this.dateStartToEnd.length > 0) {
        const options = {
          dateStart: this.dateStartToEnd[0],
          dateEnd: this.dateStartToEnd[1]
        }
        const lastTwoDaysData = await bankService.GetDateStartToDateEnd(options)
        this.listCompleteStatement = lastTwoDaysData.filter(
          this.completeFilterCondition
        )
        this.listErrorStatement = lastTwoDaysData.filter(
          x => !this.completeFilterCondition(x)
        )
      } else {
        await this.loadSummary()
      }
      this.isLoading = false
    }
  }
})
</script>
