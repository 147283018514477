<template>
  <div>
    <DarkModeSwitcher isHide="false"/>
    <div class="container sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <!-- BEGIN: Login Info -->
        <div class="flex-col hidden min-h-screen xl:flex">
          <div class="my-auto">
            <div class="flex flex-row items-center">
              <img alt="lw" class="-intro-x" style="margin-top: -50;margin-left: -50px;"
                :src="darkMode ? require('@/assets/images/lw-logo-name.png') : require('@/assets/images/lw-logo-name-dark.png')" />
            </div>
            <!-- <div
              v-if="darkMode"
              class="w-3/4 -mt-20 text-5xl font-medium leading-tight text-center text-black -intro-x" style="font-family: 'Readex Pro', 'Athiti'; z-index: 99;"
            >
              Bank Account Automation
            </div>
            <div
              v-else
              class="w-3/4 -mt-20 text-5xl font-medium leading-tight text-center text-white -intro-x" style="font-family: 'Readex Pro', 'Athiti'; margin-top: 20px;"
            >
              Bank Account Automation
            </div> -->
            <!-- <div
              class="mt-5 text-lg text-white -intro-x text-opacity-70 dark:text-gray-500"
            >
              Manage all your e-commerce accounts in one place
            </div> -->
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->

        <div class="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0">
          <div
            class="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-32 dark:bg-dark-1 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto">
            <h2 class="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left">
              Sign In
            </h2>
            <div v-if="isPageLoad">
              <br />
              <Skeletor width="100%" height="50px" />
              <br />
              <Skeletor width="100%" height="50px" />
              <br />
              <Skeletor width="100%" height="50px" />
            </div>
            <form v-else @submit.prevent="handleLogin">
              <div class="mt-2 text-center text-gray-500 intro-x xl:hidden">
                A few more clicks to sign in to your account. Manage all your
                e-commerce accounts in one place
              </div>
              <div class="mt-8 intro-x">
              <div class="border-gray-300 input-group login__input focus:intro-x">
                <div id="input-group-username" class="input-group-text" >
                  <UserIcon class="block w-3 mx-auto" />
                </div>
                <input :disabled="isLogging"
                  type="text"
                  v-model.trim="validate.username.$model"
                  placeholder="Username/Email"
                  class="border-gray-300 form-control"
                  aria-label="Username"
                  aria-describedby="input-group-username"
                />
              </div>
                <template v-if="validate.username.$error">
                  <div v-for="(error, index) in validate.username.$errors" :key="index" class="mt-2 text-theme-6">
                    {{ error.$message }}
                  </div>
                </template>
                <div class="mt-4 border-gray-300 input-group login__input focus:intro-x ">
                  <div id="input-group-password " class="input-group-text " >
                    <LockIcon class="block w-3 mx-auto" />
                  </div>
                  <input :disabled="isLogging"
                    :type="isShowPassword ? 'text' : 'password'"
                    placeholder="Password"
                    v-model.trim="validate.password.$model"
                    class="border-gray-300 form-control"
                    aria-label="Password"
                    aria-describedby="input-group-password"
                  />
                  <div id="input-group-password " :aria-disabled="isLogging" class="cursor-pointer input-group-text" @click="isShowPassword = !isShowPassword">
                    <EyeOffIcon v-if="isShowPassword" class="block w-3 mx-auto " />
                    <EyeIcon v-else class="block w-3 mx-auto " />
                  </div>
                </div>
                <template v-if="validate.password.$error">
                  <div v-for="(error, index) in validate.password.$errors" :key="index" class="mt-2 text-theme-6">
                    {{ error.$message }}
                  </div>
                </template>
                <template v-if="state.invalidLogin">
                  <div class="mt-2 text-theme-6">
                    {{ state.errorMessage }}
                  </div>
                </template>
              </div>
              <div class="flex text-xs text-gray-700 intro-x dark:text-gray-600 sm:text-sm mt-7">
                <div class="flex items-center mr-auto">
                  <input :disabled="isLogging" id="remember-me" type="checkbox" class="mr-2 border form-check-input" />
                  <label :disabled="isLogging" class="cursor-pointer select-none" for="remember-me">Remember me</label>
                </div>
                <router-link to="/forgot-password">Forgot Password?</router-link>
              </div>
              <div class="mt-5 text-center intro-x xl:mt-8 xl:text-left">
                <button :disabled="isLogging" class="w-full px-4 py-3 align-top btn btn-primary xl:w-32 xl:mr-3">
                  <div class="flex flex-col items-center justify-end sm:flex-row">
                    <div class="text-center">
                      {{ isLogging ? 'Logging' : 'Login' }}
                    </div>
                    <LoadingIcon v-if="isLogging" icon="oval" class="w-5 h-5 ml-3 text-center" />
                  </div>
                </button>
                <button type="button" :disabled="isLogging"
                  class="w-full px-4 py-3 mt-3 align-top btn btn-outline-secondary xl:w-32 xl:mt-0" @click="handleSignUp">
                  <div class="flex flex-col items-center justify-end sm:flex-row">
                    <div class="text-center">
                      Sign up
                    </div>
                  </div>
                </button>
              </div>
            </form>
            <hr v-if="false" class="mt-5 mb-5" />
            <!-- <div v-if="!isPageLoad" class="flex justify-center intro-x xl:justify-start lg:justify-start"> -->
            <div v-if="false" class="flex justify-center intro-x xl:justify-start lg:justify-start">
              <button class="self-center" @click="handleLoginWithGoogle">
                <img alt="Sign in with Google" class="h-12" v-if="darkMode"
                  src="@/assets/images/btn-sign-in-with-google.png" />
                <img alt="Sign in with Google" class="h-12" v-if="!darkMode"
                  src="@/assets/images/btn-sign-in-with-google-light.png" />
              </button>
            </div>
            <div class="mt-10 text-center text-gray-700 intro-x xl:mt-10 dark:text-gray-600 xl:text-left">
              By signing up, you agree to our <br />
              <a class="text-theme-1 dark:text-theme-10" href="https://www.lightworkai.com/termofservice">Terms and
                Conditions</a>
              &
              <a class="text-theme-1 dark:text-theme-10" href="https://www.lightworkai.com/privacypolicy">Privacy Policy</a>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
        <!-- BEGIN: Success Notification Content -->
        <div id="signin-success-notification-content" class="flex hidden toastify-content">
          <CheckCircleIcon class="text-theme-9" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Sign In success!</div>
            <div class="mt-1 text-gray-600">
              Let's go!
            </div>
          </div>
        </div>
        <!-- END: Success Notification Content -->
        <!-- BEGIN: Failed Notification Content -->
        <div id="validate-failed-notification-content" class="flex hidden toastify-content">
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Sign In failed!</div>
            <div class="mt-1 text-gray-600">
              Please check the field form.
            </div>
          </div>
        </div>
        <!-- END: Failed Notification Content -->
        <!-- BEGIN: Signin Failed Notification Content -->
        <div id="signin-failed-notification-content" class="flex hidden toastify-content">
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Login failed!</div>
            <div class="mt-1 text-gray-600">
              {{ state.errorMessage }}
            </div>
          </div>
        </div>
        <!-- END: Signin Failed Notification Content -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
import { helpers, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { securityService, authService, bankService } from '@/services'
import { useStore } from '@/store'

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  setup() {
    const store = useStore()
    // eslint-disable-next-line no-unused-vars
    const darkMode = computed(() => store.state.main.darkMode)
    window.localStorage.clear()

    const state = reactive({ invalidLogin: false, errorMessage: '' })
    const router = useRouter()
    const formData = reactive({
      username: '',
      password: ''
    })

    const tempRequired = helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required)
    const rules = {
      username: {
        required: tempRequired
      },
      password: {
        required: tempRequired
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const displayToast = (displayType) => {
      Toastify({
        node: cash('#' + displayType + '-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const handleResponse = async function handleResponse(responseUserData) {
      if (responseUserData && responseUserData.userPackage && responseUserData.userPackage.length > 0) {
        const bankConfigRes = await bankService.GetBankStatementConfig()
        if (bankConfigRes) {
          responseUserData.totalQuota = +responseUserData.userPackage[0].accountAmout
          responseUserData.totalUsedQuota = bankConfigRes.filter(x => x.isUserEnable === true).length
          responseUserData.quotaExpireDate = responseUserData.userPackage[0].expireDate
          const quota = {
            totalQuota: +responseUserData.userPackage[0].accountAmout,
            totalUsedQuota: bankConfigRes.filter(x => x.isUserEnable === true).length,
            quotaExpireDate: responseUserData.userPackage[0].expireDate
          }
          store.dispatch('main/setQuotaData', quota)
        }
      }
      const responseProvider = await authService.getListProviderConnectFromUser()
      if (responseProvider?.length > 0) {
        store.dispatch('main/setProvider', responseProvider)
      }
      localStorage.setItem('USER_PROFILE', JSON.stringify(responseUserData))
      // displayToast('signin-success')
      setTimeout(function () { router.push('/') }, 1000)
    }
    const handleLogin = async function handleLogin() {
      state.invalidLogin = false
      state.errorMessage = ''
      validate.value.$touch()
      if (validate.value.$invalid) {
        displayToast('validate-failed')
      } else {
        this.isLogging = true
        try {
          const response = await authService.Login({
            username: formData.username,
            password: formData.password
          })
          if (!this.$helpers.isNull(response.statusCode)) {
            state.invalidLogin = true
            state.errorMessage = response.message
            displayToast('signin-failed')
            this.isLogging = false
          } else if (response.access_token) {
            localStorage.setItem('accessToken', response.access_token)
            authService.GetProfile().then(handleResponse)
          }
        } catch (error) {
          state.invalidLogin = true
          state.errorMessage = error.message
          displayToast('signin-failed')
          this.isLogging = false
        }
      }
    }

    const handleSignUp = () => {
      router.push('/register')
    }

    const handleLoginWithGoogle = () => {
      window.location.href = 'https://accounts.google.com/o/oauth2/auth?scope=email&include_granted_scopes=true&redirect_uri=https://app.corgio.io/login&response_type=code&client_id=417180603507-cmq6lgn65amue7orcigmj3c8h4ojebap.apps.googleusercontent.com'
    }

    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    const goToHome = () => {
      setTimeout(function () { router.push('/') }, 1000)
    }
    return {
      validate,
      formData,
      handleLogin,
      handleSignUp,
      state,
      handleLoginWithGoogle,
      router,
      displayToast,
      darkMode,
      goToHome
    }
  },
  data() {
    return {
      isPageLoad: true,
      isLogging: false,
      isShowPassword: false
    }
  },
  methods: {
    async handleLoginByToken(token) {
      try {
        const response = await authService.LoginByToken({
          username: '$@323313',
          password: 'iknowwhatyouwant',
          lwToken: token
        })
        console.log(response)
        if (response.statusCode === 500 || response.statusCode === 401) {
          this.state.invalidLogin = true
          this.state.errorMessage = 'Username หรือ Password ของคุณไม่ถูกต้องกรุณากรอกใหม่'
          this.displayToast('signin-failed')
          return
        }
        if (response.access_token) {
          localStorage.setItem('accessToken', response.access_token)
          const responseUserData = await authService.GetProfile()
          if (responseUserData && responseUserData.userPackage && responseUserData.userPackage.length > 0) {
            const bankConfigRes = await bankService.GetBankStatementConfig()
            if (bankConfigRes) {
              responseUserData.totalQuota = +responseUserData.userPackage[0].accountAmout
              responseUserData.totalUsedQuota = bankConfigRes.filter(x => x.isUserEnable === true).length
              responseUserData.quotaExpireDate = responseUserData.userPackage[0].expireDate
              const quota = {
                totalQuota: +responseUserData.userPackage[0].accountAmout,
                totalUsedQuota: bankConfigRes.filter(x => x.isUserEnable === true).length,
                quotaExpireDate: responseUserData.userPackage[0].expireDate
              }
              this.$store.dispatch('main/setQuotaData', quota)
            }
          }
          const responseProvider = await authService.getListProviderConnectFromUser()
          if (responseProvider) {
            this.$store.dispatch('main/setProvider', responseProvider)
          }
          console.log(responseUserData)
          localStorage.setItem('USER_PROFILE', JSON.stringify(responseUserData))
          // this.displayToast('signin-success')
          this.goToHome()
        }
      } catch (e) {
        this.state.invalidLogin = true
        this.state.errorMessage = 'Username หรือ Password ของคุณไม่ถูกต้องกรุณากรอกใหม่'
        this.displayToast('signin-failed')
      }
    }
  },
  mounted() {
    const codeQuery = this.$route.query.code
    const tokenQuery = this.$route.query.token
    let normalLogin = !codeQuery && !tokenQuery
    if (codeQuery) {
      securityService.signInWithGoogle(codeQuery).then((response) => {
        const { token, error, authentication } = response
        if (error) {
          this.state.invalidLogin = true
          this.state.errorMessage = 'Username or password wrong.'
          this.displayToast('signin-failed')
        }

        if (token) {
          localStorage.setItem('accessToken', token)
          securityService.getUserById(authentication.userId).then((responseUserData) => {
            this.state.invalidLogin = false
            this.state.errorMessage = ''
            localStorage.setItem('USER_PROFILE', JSON.stringify(responseUserData))
            // this.displayToast('signin-success')
            this.$router.push('/')
          }).catch((error) => {
            this.state.invalidLogin = true
            this.state.errorMessage = error.message
            this.displayToast('signin-failed')
          })
        }
      }).catch((error) => {
        this.state.invalidLogin = true
        this.state.errorMessage = error.message
        this.displayToast('signin-failed')
      })
    }
    if (tokenQuery) {
      this.handleLoginByToken(tokenQuery)
    } else {
      normalLogin = true
    }
    if (normalLogin) {
      this.isPageLoad = false
    }
  }
})
</script>
