<template>
  <div class="modal modal-slide-over" data-backdrop="static" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg" v-if="isShow">
      <div class="modal-content">
        <a data-dismiss="modal" ref="elClose" href="javascript:;" :disabled="isUpdating" @click="onCloseClicked">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <!-- BEGIN: Slide Over Header -->
        <div class="modal-header">
          <h2 class="mr-auto text-base font-medium">
            Edit Bank Account
          </h2>
          <div class="sm:flex">
            <div class="mr-1">Status : </div>
            <div v-if="Boolean(refData.isSystemEnable) === true && Boolean(refData.isUserEnable) === true
              " class="flex items-center justify-center text-color-highlight">
              Active
            </div>
            <div v-else-if="Boolean(refData.isSystemEnable) === false"
              class="flex items-center justify-center text-color-error">
              Error
            </div>
            <div v-else-if="Boolean(refData.isUserEnable) === false" class="flex items-center justify-center">
              Inactive
            </div>
          </div>
        </div>
        <!-- END: Slide Over Header -->
        <!-- BEGIN: Slide Over Body -->
        <div class="modal-body ">
          <div v-if="refData.bankId === -1">
            <div class="-mt-2">
              <div class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Code : </label>
                <label class="ml-2 form-label">{{ refData.bankCode }}</label>
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Bank : </label>
                <label class="ml-2 form-label">{{ refData.bankGroupShortNameEn }}</label>
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Account Number : </label>
                <label class="ml-2 form-label">{{ refData.bankAccountNo }}</label>
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col items-center mt-2 sm:flex-row">
                <label class="form-label">Credentials : </label>
                <label class="ml-2 form-label">{{ refData?.scretVaultName ?? '-' }}</label>
                <div v-if="getFilterSecretVaultBySecretVault != null" class="ml-2 -mt-2">
                  <img v-if="getFilterSecretVaultBySecretVault.isExpiryDate" style="box-shadow: none;" class="w-8"
                    alt="imgExpired" :src="imgExpired" />
                  <img v-if="getFilterSecretVaultBySecretVault.isAboutExpiry" style="box-shadow: none;" class="w-8"
                    alt="imgDeadlineExpired" :src="imgDeadlineExpired" />
                  <img v-if="!getFilterSecretVaultBySecretVault.isVault" style="box-shadow: none;" class="w-8"
                    alt="imgSecurityWarning" :src="imgSecurityWarning" />
                </div>
              </div>
              <div v-if="getFilterSecretVaultBySecretVault != null">
                <div class="text-theme-6" v-if="getFilterSecretVaultBySecretVault.isExpiryDate">
                  ** Credential has expired.
                </div>
                <div class="text-yellow-500" v-if="getFilterSecretVaultBySecretVault.isAboutExpiry">
                  ** The credential is about to expire.
                </div>
                <div class="text-yellow-500" v-if="!getFilterSecretVaultBySecretVault.isVault">
                  ** The credential has crashed. Please update new information.
                </div>
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col mt-2 sm:flex-row">
                <div class="flex flex-col sm:flex-row">
                  <label class="form-label">Type</label>
                  <label class="ml-2 mr-3 text-theme-6">*</label>
                </div>
                <div class="mr-2 -mt-2 form-check" v-for="(item, index) in listBankStatementType" :key="index">
                  <input :disabled="true" v-model="newData.bankStatementTypeId" :id="'radio-switch-' + index"
                    class="form-check-input" type="radio" name="listBankAccountType_radio_button"
                    @change="onSelectBankNameChanged($event)" :value="item.id" />
                  <label class="form-check-label" style="cursor:auto">{{ item.name }}</label>
                </div>
                <LoadingIcon v-if="!isLoaded" icon="oval" class="w-8 h-8 ml-2" />
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Bank Site</label>
                <label class="ml-2 text-theme-6">*</label>
              </div>
              <div class="flex flex-col items-center justify-end mt-2 sm:flex-row">
                <TomSelect :disabled="!isLoaded || isDownloadingBankServices || isUpdating" v-model="newData.bankId"
                  class="w-full" @change="onSelectBankNameChanged($event)" placeholder="Select Bank">
                  <option v-for="option in listBankName" :key="option.id" :value="option.id">
                    {{ `${option.bankGroupShortNameEn} - ${option.bankShortName}|||${option.iconClass}` }}
                  </option>
                </TomSelect>
                <LoadingIcon v-if="!isLoaded" icon="oval" class="w-8 h-8 ml-2" />
              </div>
              <div v-if="$helpers.isNull(newData?.bankId)" class="mt-2 text-theme-6">
                This field is required
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Service</label>
                <label class="ml-2 text-theme-6">*</label>
              </div>
              <div v-if="isDownloadingBankServices || listBankAccountService.length > 0">
                <div class="flex flex-col items-center justify-end mt-2 sm:flex-row">
                  <TomSelect :disabled="isDownloadingBankServices || isUpdating" v-model="newData.bankServiceId"
                    class="w-full" placeholder="Select Service">
                    <option v-for="option in listBankAccountService" :key="option.id" :value="option.bankServiceTypeId">
                      {{ option.bankServiceTypeName }}
                    </option>
                  </TomSelect>
                  <LoadingIcon v-if="!isLoaded || isDownloadingBankServices" icon="oval" class="w-8 h-8 ml-2" />
                </div>
                <div v-if="!isLoaded && !isDownloadingBankServices && $helpers.isNull(newData?.bankServiceId)"
                  class="mt-2 text-theme-6">
                  This field is required
                </div>
              </div>
              <div v-else class="mt-2 mb-3 ml-1 text-theme-6"> No Service, please select other bank or type. </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Working Date</label>
                <label class="ml-2 text-theme-6">*</label>
              </div>
              <div class="flex flex-col items-center justify-end mt-2 sm:flex-row">
                <TomSelect v-model="newData.workingDateDay" :disabled="true" class="w-full" placeholder="Select Service">
                  <option v-for="option in listWorkingDate" :key="option.id" :value="option.days">
                    {{ option.name }}
                  </option>
                </TomSelect>
                <LoadingIcon v-if="!isLoaded" icon="oval" class="w-8 h-8 ml-2" />
              </div>
              <div v-if="newData.workingDateDay === '' || newData.workingDateDay === null
                " class="mt-2 text-theme-6">
                This field is required
              </div>
            </div>
            <div class="mt-3">
              <label class="form-label">Company</label>
              <input :disabled="isUpdating" v-model="newData.company" type="text" class="form-control"
                placeholder="Company Name" />
            </div>
          </div>
          <div v-else>
            <div class="-mt-2">
              <div class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Code : </label>
                <label class="ml-2 form-label">{{ refData.bankCode }}</label>
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Type : </label>
                <label class="ml-2 form-label">{{ refData.statementType }}</label>
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col mt-2 sm:flex-row" v-if="isSyncProvider">
                <label class="form-label">Account Number : </label>
                <label class="ml-2 form-label">{{ refData.bankAccountNo }}</label>
              </div>
            </div>
            <div class="mt-3">
              <div v-if="isSyncProvider">
                <div class="flex flex-col mt-2 sm:flex-row">
                  <label class="form-label">Bank</label>
                  <label class="ml-2 text-theme-6">*</label>
                </div>
                <div class="flex flex-col items-center justify-end mt-2 sm:flex-row">
                  <TomSelect :disabled="!isLoaded || isDownloadingBankServices || isUpdating" v-model="newData.bankId"
                    class="w-full" @change="onSelectBankNameChanged($event)" placeholder="Select Bank">
                    <option v-for="option in listBankName" :key="option.id" :value="option.id">
                      {{ `${option.bankGroupShortNameEn} - ${option.bankShortName}|||${option.iconClass}` }}
                    </option>
                  </TomSelect>
                  <LoadingIcon v-if="!isLoaded" icon="oval" class="w-8 h-8 ml-2" />
                </div>
              </div>
              <div v-else class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Bank : </label>
                <i :class="refData.iconClass" class="ml-2" />
                <label class="ml-2 form-label">{{ refData.bankGroupShortNameEn }} - {{ refData.bankShortName }}</label>
              </div>
            </div>
            <div class="mt-3">
              <div v-if="isSyncProvider">
                <div class="flex flex-col mt-2 sm:flex-row">
                  <label class="form-label">Service : </label>
                </div>
                <div class="flex flex-col items-center justify-end mt-2 sm:flex-row">
                  <TomSelect :disabled="isDownloadingBankServices || isUpdating" v-model="newData.bankServiceId"
                    class="w-full" placeholder="Select Service" @change="getBankAccountService">
                    <option v-for="option in listBankAccountService" :key="option.id" :value="option.bankServiceTypeId">
                      {{ option.bankServiceTypeName }}
                    </option>
                  </TomSelect>
                  <LoadingIcon v-if="!isLoaded || isDownloadingBankServices" icon="oval" class="w-8 h-8 ml-2" />
                </div>
              </div>
              <div v-else class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Service : </label>
                <label class="ml-2 form-label">{{ refData.bankServiceTypeName }}</label>
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col items-center mt-2 sm:flex-row">
                <label class="form-label">Credentials : </label>
                <label class="ml-2 form-label">{{ refData?.scretVaultName ?? '-' }}</label>
                <div v-if="getFilterSecretVaultBySecretVault != null" class="ml-2 -mt-2">
                  <img v-if="getFilterSecretVaultBySecretVault.isExpiryDate" style="box-shadow: none;" class="w-8"
                    alt="imgExpired" :src="imgExpired" />
                  <img v-if="getFilterSecretVaultBySecretVault.isAboutExpiry" style="box-shadow: none;" class="w-8"
                    alt="imgDeadlineExpired" :src="imgDeadlineExpired" />
                  <img v-if="!getFilterSecretVaultBySecretVault.isVault" style="box-shadow: none;" class="w-8"
                    alt="imgSecurityWarning" :src="imgSecurityWarning" />
                </div>
              </div>
              <div v-if="getFilterSecretVaultBySecretVault != null">
                <div class="text-theme-6" v-if="getFilterSecretVaultBySecretVault.isExpiryDate">
                  ** Credential has expired.
                </div>
                <div class="text-yellow-500" v-if="getFilterSecretVaultBySecretVault.isAboutExpiry">
                  ** The credential is about to expire.
                </div>
                <div class="text-yellow-500" v-if="!getFilterSecretVaultBySecretVault.isVault">
                  ** The Secret Vault has crashed. Please update new information.
                </div>
              </div>
            </div>
            <div class="mt-5" v-if="isSyncProvider == false">
              <div class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Account Number</label>
                <label class="ml-2 text-theme-6">*</label>
              </div>
              <input :disabled="isUpdating" @input="onInputAccountNumberInput($event)" v-model="newData.bankAccountNo"
                @keypress="onInputAccountNumberKeyPress($event)" type="text" class="form-control"
                placeholder="###-#-#####-#" />
              <div v-if="newData.bankAccountNo?.replace(/-/g, '').length === 0" class="mt-2 text-theme-6">
                This field is required
              </div>
              <div v-else-if="isMaxCharacter(newData.bankAccountNo)" class="mt-2 text-theme-6">
                This field should be at least 10 characters long
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col mt-2 sm:flex-row">
                <label class="form-label">Working Date</label>
                <label class="ml-2 text-theme-6">*</label>
              </div>
              <div class="flex flex-col items-center justify-end mt-2 sm:flex-row">
                <TomSelect v-model="newData.workingDateDay" :disabled="isSyncProvider || !isLoaded || isUpdating"
                  class="w-full" placeholder="Select Service">
                  <option v-for="option in listWorkingDate" :key="option.id" :value="option.days">
                    {{ option.name }}
                  </option>
                </TomSelect>
                <LoadingIcon v-if="!isLoaded" icon="oval" class="w-8 h-8 ml-2" />
              </div>
              <div v-if="$helpers.isNull(newData?.workingDateDay)" class="mt-2 text-theme-6">
                This field is required
              </div>
            </div>
            <div class="mt-3">
              <label class="form-label">Company</label>
              <input :disabled="isUpdating" v-model="newData.company" type="text" class="form-control"
                placeholder="Company Name" />
            </div>
          </div>
        </div>
        <!-- END: Slide Over Body -->
        <!-- BEGIN: Slide Over Footer -->
        <div class="absolute bottom-0 w-full text-right modal-footer">
          <div Class="flex col-span-12 mb-3 text-left text-theme-6" v-if="!$helpers.isNull(errorMessage)">
            <img :src="imgError" class="w-6 h-6 mr-2 " />
            <span>{{ errorMessage }}</span>
          </div>
          <button :disabled="isUpdating" @click="onCloseClicked" type="button" data-dismiss="modal"
            class="w-20 mr-5 text-left btn btn-primary">
            <div class="text-center">Cancel</div>
          </button>
          <button
            :disabled="!isLoaded || isDownloadingBankServices || isUpdating || isValidateRefData() || isValidateNewData()"
            type="button" class="h-10 mr-1 text-right btn btn-primary" @click="onUpdateClicked">
            <div class="flex flex-col items-center justify-end sm:flex-row">
              <div class="text-center">
                {{ isUpdating ? 'Updating' : 'Update' }}
              </div>
              <LoadingIcon v-if="isUpdating" icon="oval" class="w-6 h-6 ml-3 text-center" />
            </div>
          </button>
        </div>
        <!-- END: Slide Over Footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { bankService } from '@/services'
import imgExpired from '@/assets/images/Expired64.png'
import imgDeadlineExpired from '@/assets/images/DeadlineExpired64.png'
import imgSecurityWarning from '@/assets/images/SecurityWarning64.png'
import imgError from '@/assets/images/Error32.png'

export default defineComponent({
  emits: ['update'],
  data() {
    return {
      imgError,
      imgDeadlineExpired,
      imgExpired,
      imgSecurityWarning,
      refData: null,
      isShow: false,
      isUpdating: false,
      errorMessage: null,
      isDownloadingBankServices: false,
      isLoaded: false,
      listWorkingDate: [],
      listSecretVault: [],
      listBankAccountService: [],
      listBankStatementType: [],
      listBankName: [],
      newData: {}
    }
  },
  computed: {
    ...mapGetters({
      isSyncProvider: 'main/isSyncProvider'
    }),
    getFilterSecretVaultBySecretVault() {
      const filter = this.listSecretVault.filter(_ => _.id === this.refData?.scretVaultId)
      return (filter?.length > 0 ? filter[0] : null) ?? null
    }
  },
  async created() {
    await this.getWorkingDate()
    await this.getSecretVault()
  },
  methods: {
    isValidateRefData() {
      if (this.refData?.bankId !== -1) {
        if (this.isSyncProvider) {
          return (this.refData.workingDateDay === this.newData.workingDateDay) &&
            (this.refData.company === this.newData.company)
        } else {
          return (this.refData.bankAccountNo === this.newData.bankAccountNo) &&
            (this.refData.workingDateDay === this.newData.workingDateDay) &&
            (this.refData.company === this.newData.company)
        }
      }
      return false
    },
    isValidateNewData() {
      if (this.refData?.bankId !== -1) {
        if (this.isSyncProvider) {
          return this.$helpers.isNull(this.newData.bankAccountNo) ||
            this.$helpers.isNull(this.newData.workingDateDay)
        } else {
          return this.$helpers.isNull(this.newData.bankAccountNo) ||
            this.$helpers.isNull(this.newData.workingDateDay) ||
            this.isMaxCharacter(this.newData.bankAccountNo)
        }
      } else {
        return this.$helpers.isNull(this.newData.bankStatementTypeId) ||
          this.$helpers.isNull(this.newData.bankId) ||
          this.$helpers.isNull(this.newData.bankServiceId) ||
          this.$helpers.isNull(this.newData.workingDateDay)
      }
    },
    isMaxCharacter(data) {
      return data?.replace(/-/g, '').trim().length !== 10
    },
    async open(data) {
      this.refData = { ...data }
      this.isShow = true
      await this.getBankStatementType()
      this.newData.bankStatementTypeId = this.listBankStatementType[0]?.id
      await this.getBankName()
      this.newData.bankId = this.refData.bankId === -1 ? this.listBankName[0]?.id : this.refData.bankId

      if (this.refData.bankId !== -1) {
        this.newData.bankServiceId = data.bankServiceTypeId
        this.newData.bankAccountNo = data.bankAccountNo
        this.newData.workingDateDay = data.workingDateDay
        this.newData.company = data.company
      } else {
        await this.getBankAccountService()
        this.newData.bankServiceId = this.listBankAccountService[0]?.id
        this.newData.workingDateDay = this.listWorkingDate[1].days
        this.newData.company = null
      }
      this.isLoaded = true
    },
    onInputAccountNumberInput(e) {
      if (this.refData) {
        const temp = e.target.value?.replace(/-/g, '')
        if (temp.length > 9) {
          this.newData.bankAccountNo =
            temp.slice(0, 3) +
            '-' +
            temp.slice(3, 4) +
            '-' +
            temp.slice(4, 9) +
            '-' +
            temp.slice(9, 10)
        } else if (temp.length > 4) {
          this.newData.bankAccountNo =
            temp.slice(0, 3) + '-' + temp.slice(3, 4) + '-' + temp.slice(4, 9)
        } else if (temp?.length > 3) {
          this.newData.bankAccountNo = temp.slice(0, 3) + '-' + temp.slice(3, 4)
        }
      }
    },
    onInputAccountNumberKeyPress(e) {
      if (e.target.value?.replace(/-/g, '').length > 9) {
        e.preventDefault()
      }
      const charCode = e.which ? e.which : e.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        e.preventDefault()
      } else {
        return true
      }
    },
    async onSelectBankNameChanged(e) {
      await this.getBankAccountService()
    },
    async onUpdateClicked() {
      this.isUpdating = true
      this.errorMessage = null
      try {
        let payload = null
        if (this.refData.bankId !== -1) {
          payload = {
            isInfo: !this.isSyncProvider,
            isProvider: this.isSyncProvider,
            bankStatementTypeId: this.newData.bankStatementTypeId,
            bankServiceId: this.newData.bankServiceId,
            bankId: this.newData.bankId,
            bankAccountNo: this.newData.bankAccountNo,
            workingDateDay: this.newData.workingDateDay,
            company: this.newData.company
          }
        } else {
          payload = {
            isInfo: false,
            isProvider: this.isSyncProvider,
            bankStatementTypeId: this.newData.bankStatementTypeId,
            bankServiceId: this.newData.bankServiceId,
            bankId: this.newData.bankId,
            bankAccountNo: null,
            workingDateDay: this.newData.workingDateDay,
            company: this.newData.company
          }
        }
        const res = await bankService.UpdateBankStatementConfig(this.refData.id, payload)
        if (this.$helpers.isNull(res?.statusCode)) {
          this.$helpers.showToastify('Updated bank "' + this.refData.bankCode + '" successfully.'
          )
          this.$refs.elClose.click()
          this.$emit('update', this)
        } else {
          this.isUpdating = false
          this.errorMessage = res.message
        }
      } catch (e) {
        this.isUpdating = false
        this.errorMessage = e.message
      }
    },
    async onCloseClicked() {
      await this.delay(500)
      this.isShow = false
      this.refData = null
      this.isUpdating = false
      this.isLoaded = false
      this.isDownloadingBankServices = false
      this.errorMessage = null
      this.newData = {}
    },
    async getWorkingDate() {
      this.listWorkingDate = await bankService.GetWorkingDate()
    },
    async getSecretVault() {
      this.listSecretVault = await bankService.GetSecretVault()
    },
    async getBankName() {
      this.listBankName = await bankService.GetBankNameGroup(this.refData.refBankCode)
    },
    async getBankAccountService() {
      this.listBankAccountService = []
      if (typeof this.newData.bankServiceId === 'undefined') {
        this.newData.bankServiceId = null
      }
      if (this.newData?.bankId === '' || this.newData?.bankId === null) {
        this.listBankAccountService = []
      } else {
        this.isDownloadingBankServices = true
        const res = await bankService.GetBankAccountService({
          bankid: this.newData.bankId,
          statementtypeid: this.newData.bankStatementTypeId
        })
        // this.listBankAccountService = res
        // if (res.length > 0) {
        //   this.newData.bankServiceId = res[0]?.bankServiceTypeId
        // }
        if (this.isSyncProvider) {
          this.listBankAccountService = res.filter(item =>
            !(item.bankNameId === 39 && item.bankServiceTypeId === 1) &&
            !(item.bankNameId === 2 && item.bankServiceTypeId === 16)
          )
          // console.log('this.listBankAccountService', this.listBankAccountService)
        } else {
          this.listBankAccountService = res
        }

        if (this.listBankAccountService.length > 0) {
          this.newData.bankServiceId = this.listBankAccountService[0]?.bankServiceTypeId
        }
        this.isDownloadingBankServices = false
      }
    },
    async getBankStatementType() {
      this.listBankStatementType = await bankService.GetBankStatementType()
      if (this.isSyncProvider) {
        this.listBankStatementType = this.listBankStatementType.filter(item => item.name !== 'Bill Payment')
      }
    },
    async delay(milliseconds) {
      return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
      })
    }
  }
})
</script>
